import Util from "../services/util";

export const Appinfo = {
  isSoft: () => window.navigator.userAgent.includes("Electron"),
  name: "玉东财云",
  logo: `/icons/png/144x144.png`,
};

export const orgRoles = [
  { value: "admin", title: "管理员" },
  { value: "accountants", title: "财务人员" },
];

export const orgRolesName: {
  [orgRoles: string]: {
    value: string;
    title: string;
  };
} = Util.toObject(orgRoles, "value");

export const financeRoles = [
  { value: "manage", title: "账套主管" },
  { value: "accountant", title: "会计" },
  { value: "cashier", title: "出纳" },
];
export const financeRolesName: {
  [financeRoles: string]: string;
} = Util.toObject(financeRoles, "value", "title");

export const financeStandards = [
  { value: "mcom", title: "小企业" },
  { value: "ny", title: "农民专业合作社" },
];
export const financeStandardsName: { [x: string]: string } = Util.toObject(
  financeStandards,
  "value",
  "title"
);
export const voucherTypeOptions = ["记", "收", "转", "付"].map((i) => ({
  value: i,
  title: i,
}));

// export const financeRoot: IfinanceCategory[] = ['资产', '负债', '权益', '成本', '损益'].map((i, index) => ({
//   code: (index + 1).toString(),
//   name: i,
//   py: pinyin.getFirstLetter(i).join(''), status: true, de: true
// }))
