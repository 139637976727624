import axios from "axios";
import { login, progress, snk, Snk } from "./app.service";

function randomString(len = 32) {
  let rdmStr = "";
  for (
    rdmStr;
    rdmStr.length < len;
    rdmStr += Math.random().toString(36).substring(2)
  ) {}
  return rdmStr.substring(0, len);
}
export const machine =
  localStorage.getItem("machine") ||
  (() => {
    const m = randomString(64);
    localStorage.setItem("machine", m);
    return m;
  })();

// api
export const APIHOST = window.location.host.includes("localhost:")
  ? "http://localhost:3066/"
  : "/";

//==============================={ axios 请求拦截 }
export const apiInit = () => {
  axios.interceptors.request.use((config) => {
    if (config.method === "post" && !(config.data && config.data.$noToken)) {
      config.baseURL = APIHOST;
      (config.headers as any)["token"] = localStorage.getItem("token") || "";
      (config.headers as any)["machine"] = machine;
      progress.value++;
      progress.sub.next(progress.value);
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      progress.value--;
      progress.sub.next(progress.value);
      if (response?.headers) {
        if (response?.headers.token) {
          localStorage.setItem("token", response?.headers.token);
        }
      }
      const status = response?.status || (response as any).statusCode;

      if (status >= 300 || status < 200) {
        const snkValue: Snk = {
          open: true,
          msg:
            status === 403
              ? "无权限"
              : response.statusText || (response as any).message,
          severity: "error",
        };
        snk.next(snkValue);
      }
      return response;
    },
    (error) => {
      progress.value--;
      progress.sub.next(progress.value);
      switch (error?.response?.status) {
        case 403:
          if (window.location.pathname.substring(0, 5) === "/soft") {
            snk.next({
              open: true,
              autoHideDuration: 10000,
              severity: "error",
              msg: `无权访问`,
            });
            login.loginForm.next(true);
          }
          break;
        default:
          snk.next({
            open: true,
            autoHideDuration: 10000,
            severity: "error",
            msg: `${error?.response?.data.message || error?.name || "错误"}:${
              error?.message || "未知错误"
            }`,
          });
      }
      return error?.response?.data;
    }
  );
};
