import {
  BookOnlineOutlined,
  Business,
  PollOutlined,
  Roofing,
  Settings,
  Subtitles,
  SupervisorAccount,
} from "@mui/icons-material";

export type Imenu = {
  title: string;
  name: string;
  roles?: string[];
  icon?: JSX.Element;
  children?: Imenu[];
};

export const systemMenu: Imenu[] = [
  {
    title: "账号",
    name: "member",
    roles: ["admin"],
    icon: <SupervisorAccount />,
  },
  {
    title: "企业",
    name: "org",
    roles: ["admin"],
    icon: <Business />,
  },
];

export const orgMenu: Imenu[] = [
  {
    title: "设置",
    name: "orgMain",
    icon: <Settings />,
    roles: ["admin"],
  },
];

export const financeMenu: Imenu[] = [
  {
    title: "账套设置",
    name: "financeSetting",
    icon: <Roofing />,
    roles: [],
    children: [
      {
        title: "科目设置",
        name: "",
      },
      {
        title: "期初余额",
        name: "InitialBalance",
      },
      {
        title: "币种",
        name: "currency",
      },
    ],
  },
  {
    title: "凭证",
    name: "voucher",
    icon: <Subtitles />,
    roles: [],
    children: [
      { title: "录入凭证", name: "voucherAdd" },
      { title: "查看凭证", name: "" },
      { title: "发票", name: "invoice" },
    ],
  },
  {
    title: "账簿",
    name: "accountsBook",
    icon: <BookOnlineOutlined />,
    roles: [],
    children: [
      { title: "明细账", name: "" },
      { title: "总账", name: "generalLedger" },
      { title: "科目余额表", name: "trialBalance" },
      { title: "科目汇总表", name: "categorizedAccountsSummary" },
      { title: "序时账", name: "journal" },
      { title: "核算项目明细账", name: "aaSubsidiaryLedger" },
      { title: "核算项目余额表", name: "aaTrialBalance" },
      { title: "多栏账", name: "multiColumnLedger" },
    ],
  },
  {
    title: "报表",
    name: "reports",
    icon: <PollOutlined />,
    roles: [],
    children: [
      { title: "资产负债表", name: "" },
      { title: "利润表", name: "incomeStatement" },
      { title: "利润表季报", name: "incomeQuarterSheet" },
      { title: "现金流量表", name: "cashFlowStatement" },
      { title: "现金流量表季报", name: "cashFlowQuarterStatement" },
      { title: "财务摘要信息", name: "financialSummary" },
    ],
  },
];
