import {
  AppBar,
  Button,
  Container,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Appinfo } from "../common/config";
import Logo from "../ui/logo";

export default function WebSiteHome() {
  const navigate = useNavigate();
  return (
    <Stack>
      <AppBar component="nav">
        <Toolbar>
          <Logo title={Appinfo.name} />
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Toolbar></Toolbar>
        <Stack
          sx={{ p: 5, height: "80vh" }}
          alignItems="center"
          justifyContent="center"
          spacing={10}>
          <Typography variant="h1">极简</Typography>
          <Typography variant="h2">1人搞定100家账务</Typography>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              navigate("/1");
            }}>
            立刻开始
          </Button>
        </Stack>
      </Container>
    </Stack>
  );
}
