import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Appinfo } from "../common/config";

export default function Logo(props: {
  isBlue?: boolean;
  height?: number;
  onlyIcon?: boolean;
  title?: string;
}) {
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        cursor: "pointer",
      }}
      onClick={() => navigate("/")}>
      <img
        style={{ height: "2rem", width: "2rem" }}
        src={Appinfo.logo}
        alt=""
      />
      <Typography variant="h5">{props.title}</Typography>
    </Stack>
  );
}
