import { Add } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { firstValueFrom } from "rxjs";
import SparkMD5 from "spark-md5";
import { IFormEvent } from "../form/Form.interface";
import FormText from "../form/FormText";
import { APIHOST } from "../services/api.service";
import { login } from "../services/app.service";
import ImgsetService from "../services/imgset.service";
import Util from "../services/util";

export default function AccountName(props: { onClose: (e?: any) => void }) {
  const [form, setForm] = React.useState({
    nickname: "",
    avatar: "",
    ...login.me,
  });
  function handleFormChange(e: IFormEvent) {
    setForm(Util.formChange(e, form));
  }

  async function handleSave() {
    if (!login.me?._id) return;
    const { nickname, avatar } = form;
    const r = await axios.post("open/accountSetName", { nickname, avatar });
    if (r?.data._id) {
      login.me = r.data;
      login.changed.next(login.me);
      props.onClose();
    }
  }

  return (
    <Dialog open={true} onClose={() => props.onClose()} maxWidth="xs">
      <DialogTitle>昵称/头像</DialogTitle>
      {form && (
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack direction="row" alignItems={"center"} spacing={2}>
              <Avatar srcSet={form.avatar} />
              <FormText
                label="昵称"
                name="nickname"
                value={form.nickname}
                onChange={handleFormChange}
              />
            </Stack>
            <Divider />
            <Stack direction="row" flexWrap="wrap">
              {[
                Array(9)
                  .fill(1)
                  .map((i, index) => (
                    <IconButton
                      onClick={() =>
                        setForm({
                          ...form,
                          avatar: `/avatar/${i + index}.svg`,
                        })
                      }>
                      <Avatar srcSet={`/avatar/${i + index}.svg`} />
                    </IconButton>
                  )),
              ]}
              <IconButton>
                <Avatar
                  onClick={() => {
                    upload().then((filename) => {
                      setForm({
                        ...form,
                        avatar: `${APIHOST}upload/${filename}`,
                      });
                    });
                  }}>
                  <Add />
                </Avatar>
              </IconButton>
            </Stack>
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => props.onClose()}>取消</Button>
        <Button variant="contained" onClick={() => handleSave()}>
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
}

async function upload(w = 40, h = 40) {
  if (!showOpenFilePicker) return;
  const [r] =
    (await showOpenFilePicker({
      accept: {
        "image/*": ".png,.gif,.jpeg,.jpg,.jpeg,.svg,.webp".split(","),
      },
      multiple: false,
    })) || [];
  const file = (await r?.getFile()) as File;
  if (!file) return;
  const fileType = file.type.split("/")[0];
  const base64 = await firstValueFrom(new ImgsetService().readimg(file, w, h));
  const filename = `${fileType}/${Date.now()}${SparkMD5.hash(
    base64
  )}.${file.name.split(".").at(-1)}`;
  await axios.post("soft/upload", {
    filename,
    file: base64,
    count: 1,
    index: 1,
  });
  return filename;
}
