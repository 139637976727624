import { DoDisturbAlt, DoneOutline, Edit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { orgRoles, orgRolesName } from "../common/config";
import { IFormEvent } from "../form/Form.interface";
import FormSelect from "../form/FormSelect";
import FormSwitch from "../form/FormSwitch";
import FormText from "../form/FormText";
import { IcontextStatue, IinMembers, Iorg } from "../interface";
import { snk } from "../services/app.service";
import { my } from "../services/my.service";
import Util from "../services/util";

export default function OrgMembers(props: {
  newMember: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}) {
  const [{ org }] = useOutletContext() as IcontextStatue;
  const [formMember, setFormMember] = React.useState<IinMembers>();
  React.useEffect(() => {
    if (props.newMember[0]) {
      setFormMember({ name: "", roles: [], mobile: "", on: true });
    }
    // eslint-disable-next-line
  }, [props.newMember[0]]);

  return org ? (
    <React.Fragment>
      <Table>
        <TableBody>
          {org.members.map((i, index) => (
            <TableRow key={index}>
              <TableCell>{i.name}</TableCell>
              <TableCell>{i.mobile}</TableCell>
              <TableCell>
                {i.roles.map((x) => orgRolesName[x]?.title).join(", ")}
              </TableCell>
              <TableCell sx={{ p: 0 }}>
                {i.on ? (
                  <DoneOutline color="success" />
                ) : (
                  <DoDisturbAlt color="disabled" />
                )}
              </TableCell>
              <TableCell sx={{ p: 0 }}>
                <IconButton
                  onClick={() => {
                    setFormMember({ ...i });
                  }}>
                  <Edit />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {formMember && (
        <FormMember
          form={formMember}
          org={org}
          onClose={() => {
            setFormMember(undefined);
            props.newMember[1](false);
          }}
        />
      )}
    </React.Fragment>
  ) : null;
}

//=============================================================={ 表单 }
function FormMember(props: {
  form: IinMembers;
  org: Iorg;
  onClose: () => void;
}) {
  const { form: _form, org, onClose } = props;
  const [form, setForm] = React.useState<IinMembers>(_form);
  function handleFormChange(e: IFormEvent): void {
    setForm(Util.formChange(e, form));
  }
  async function handleSave() {
    const members = [...org.members];
    if (form._id) {
      const index = members.findIndex((i) => i._id === form._id);
      members[index] = form;
      saveMembers(members);
    } else {
      if (members.findIndex((i) => i.mobile === form.mobile)) {
        snk.next({ msg: "用户已经存在" });
      } else {
        const {
          data: { _id },
        } = await axios.post("org/memberinfoForMb", {
          mobile: form.mobile,
        });
        if (_id) {
          members.push({ ...form, _id });
          saveMembers(members);
        } else {
          snk.next({ msg: "错误", severity: "error" });
        }
      }
    }
  }

  async function saveMembers(members: IinMembers[]) {
    if (!members.find((i) => i.on && i.roles.includes("admin"))) {
      snk.next({ msg: "至少有一名管理员", severity: "error" });
    } else {
      await axios.post("org/saveOrgMembers", {
        _id: org._id,
        orgId: org._id,
        members,
      });
      my.getorg();
      onClose();
    }
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{form._id ? `修改(${form.mobile})` : "添加"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2, minWidth: 300 }}>
          <FormText
            label="姓名"
            name="name"
            value={form.name}
            onChange={handleFormChange}
          />
          {!form._id && (
            <FormText
              label="手机号"
              value={form.mobile}
              name="mobile"
              onChange={handleFormChange}
            />
          )}
          <FormSelect
            sx={{ width: 200 }}
            variant="outlined"
            multiple={true}
            label="角色"
            name="roles"
            value={form.roles}
            options={orgRoles}
            onChange={handleFormChange}
          />
          <FormSwitch
            label="开启"
            name="on"
            value={form.on}
            onChange={handleFormChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button
          variant="contained"
          disabled={!/^\d{11}$/.test(form.mobile) || !form.name}
          onClick={handleSave}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
}
