import { blue, orange } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { zhCN } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AccountsBook from "./accountsBook/AccountsBook";
import AccountsBookSubsidiaryLedger from "./accountsBook/AccountsBookSubsidiaryLedger";
import Account from "./common/Account";
import OrgSetting from "./common/OrgSetting";
import FinanceInitialBalance from "./finance/FinanceInitialBalance";
import FinanceSetting from "./finance/FinanceSetting";
import FinanceSettingCategory from "./finance/FinanceSettingCategory";
import FinanceSettingCurrency from "./finance/FinanceSettingCurrency";
import Home from "./Home";
import OrgMain from "./org/OrgMain";
import BalanceSheet from "./reports/BalanceSheet";
import Reports from "./reports/Reports";
import { apiInit } from "./services/api.service";
import { colorMode, login } from "./services/app.service";
import Member from "./system/Member";
import Org from "./system/Org";
import LoginForm from "./ui/LoginForm";
import NotFound from "./ui/notfound";
import Snker from "./ui/Snker";
import Voucher from "./voucher/Voucher";
import VoucherAdd from "./voucher/VoucherFormAdd";
import VoucherInvoice from "./voucher/VoucherInvoice";
import VoucherList from "./voucher/VoucherList";
import WebSiteHome from "./website/WebSiteHome";
import { MyProgress } from "./widgets/MyProgress";
import GeneralLedger from "./accountsBook/GeneralLedger";
apiInit();

const HomeRoute = (
  <React.Fragment>
    {/* finance */}
    <Route
      path="voucher"
      element={<Voucher />}
      children={
        <React.Fragment>
          <Route path="" element={<VoucherList />} />
          <Route path="voucherAdd" element={<VoucherAdd />} />
          <Route path="invoice" element={<VoucherInvoice />} />
        </React.Fragment>
      }
    />
    <Route
      path="accountsBook"
      element={<AccountsBook />}
      children={
        <React.Fragment>
          <Route path="" element={<AccountsBookSubsidiaryLedger />} />
          <Route path="generalLedger" element={<GeneralLedger />} />
        </React.Fragment>
      }
    />
    <Route
      path="reports"
      element={<Reports />}
      children={
        <React.Fragment>
          <Route path="" element={<BalanceSheet />} />
        </React.Fragment>
      }
    />
    <Route
      path="financeSetting"
      element={<FinanceSetting />}
      children={
        <React.Fragment>
          <Route path="" element={<FinanceSettingCategory />} />
          <Route path="currency" element={<FinanceSettingCurrency />} />
          <Route path="initialBalance" element={<FinanceInitialBalance />} />
        </React.Fragment>
      }
    />
    {/* org */}
    <Route path="orgMain" element={<OrgMain />} />
    {/* system */}
    <Route path="member" element={<Member />} />
    <Route path="org" element={<Org />} />
    {/* 通用 */}
    <Route path="orgSetting" element={<OrgSetting />} />
    <Route path="account" element={<Account />} />
    <Route path="*" element={<NotFound />} />
  </React.Fragment>
);

export default function App() {
  const [isDark, setIsDark] = React.useState<boolean>(colorMode.isDark);
  useEffect(() => {
    const _sub = colorMode.sub.subscribe((v) => {
      colorMode.isDark = v;
      setIsDark(v);
    });
    return () => _sub.unsubscribe();
  });

  const theme = React.useMemo(() => {
    return createTheme(
      {
        palette: {
          mode: isDark ? "dark" : "light",
          primary: blue,
          secondary: orange,
        },
      },
      zhCN
    );
  }, [isDark]);

  React.useEffect(() => {
    login.getMe();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Snker />
        <MyProgress />
        <LoginForm />
        <Routes>
          <Route path="1" element={<Home />} children={HomeRoute} />
          <Route path="*" element={<WebSiteHome />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
