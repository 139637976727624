import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { FormProps } from "./Form.interface";

export default function FormTags(props: FormProps) {
  const { label, options, name } = props;
  const [value, setValue] = useState<string[]>(
    Array.isArray(props.value)
      ? props.value
      : (props.value as string)?.split(",").filter((i: string) => !!i) || []
  );

  const handleChange = (event: any, newValue: string[]) => {
    setValue(newValue);
    props.onChange({ target: { name, value: newValue } });
  };

  return (
    <Autocomplete
      multiple
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      value={value}
      onChange={handleChange as any}
      options={options || ([] as any)}
      renderTags={(tagValue: string[], getTagProps) =>
        tagValue.map((option, index) => (
          <Chip label={option} {...getTagProps({ index })} />
        ))
      }
      sx={props.sx}
      renderInput={(params) => (
        <TextField
          {...params}
          onBlur={(e) => {
            const newValue = e.currentTarget?.value;
            if (newValue && !value.includes(newValue)) {
              const nv = [...value, newValue];
              setValue(nv);
              props.onChange({ target: { name, value: nv } });
            }
          }}
          label={label}
        />
      )}
    />
  );
}
