import { Input, Stack } from "@mui/material";
import React from "react";
import { VoucherWidgetInputSx } from "./VoucherWidgetInputTitle";

export default function VoucherWidgetInput(props: {
  value?: number;
  onChange?: (value?: number) => void;
  onAction?: (ac: any) => void;
  readOnly?: boolean;
}) {
  function initValue() {
    const s = !!props.value
      ? Math.abs(props.value || 0)
        .toString()
        .split("")
      : [];
    if (s.length < 11) {
      s.unshift(...Array(11 - s.length).fill(""));
    }
    return { value: s, negative: (props.value || 0) < 0 }
  }

  const [value, setValue] = React.useState<string[]>(initValue().value);
  const [negative, setNegative] = React.useState<boolean>(initValue().negative);
  const refs = React.useRef<HTMLInputElement[]>([]);


  React.useEffect(() => {
    const i = initValue()
    if (i.value.join('') !== value.join('') || i.negative !== negative) {
      setValue(i.value)
      setNegative(i.negative)
    }
  }, [props.value]);

  React.useEffect(() => {
    const _v = Number(value?.join("")) || 0;
    const newValue = negative ? 0 - _v : _v;
    props.onChange && props.onChange(newValue);
  }, [value, negative])

  return (
    <Stack
      direction="row"
      sx={{
        height: "100%",
        "& input": {
          caretColor: "transparent",
          p: 0,
          height: "100%",
          textAlign: "center",
          ":focus": { bgcolor: "#29b6f630" },
        },
      }}>
      {value?.map((i, index) => (
        <Stack
          key={index}
          sx={{ height: "100%", ...VoucherWidgetInputSx(index) }}>
          <Input
            sx={{ height: "100%", ...(negative ? { color: "red" } : {}) }}
            readOnly={props.readOnly}
            value={i}
            ref={(r: HTMLDivElement) => {
              refs.current[index] = r?.firstChild as HTMLInputElement;
            }}
            onKeyUp={(e) => {
              if (props.readOnly) return;
              const { key } = e;
              if (/^\d$/.test(key)) {
                value[index] = key;
                setValue(famatValue([...value]));
                refs.current[index + 1]?.focus();
              } else if (key === "Backspace") {
                value.splice(index, 1);
                value.unshift("");
                setValue(famatValue([...value]));
              } else if (key === "ArrowLeft") {
                refs.current[index - 1]?.focus();
              } else if (key === "ArrowRight") {
                refs.current[index + 1]?.focus();
              } else if (key === "=") {
                props.onAction && props.onAction("=");
              } else if (key === "-") {
                setNegative(!negative);
              } else if (key === ".") {
                if (index === 10) {
                  value.splice(0, 2);
                  value.push("0", "0");
                } else {
                  value.splice(2 + index);
                }
                refs.current[9]?.focus();
                setValue([...Array(11 - value.length).fill(""), ...value]);
              }
            }}
          />
        </Stack>
      ))}
    </Stack>
  );

  // 补齐或填充
  function famatValue(val: string[]) {
    let flag = false;
    val.forEach((i, index) => {
      if (i && i !== "0") flag = true;
      if (flag && val[index] === "") {
        val[index] = "0";
      }
      if (!flag && val[index] === "0") {
        val[index] = "";
      }
    });
    console.log(val);

    return val;
  }
}
