import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import SparkMD5 from "spark-md5";
import { IFormEvent } from "../form/Form.interface";
import FormText from "../form/FormText";
import { APIHOST, machine } from "../services/api.service";
import { login, snk } from "../services/app.service";
import Util from "../services/util";
import LoginFormQr from "./LoginFormQr";

export default function LoginForm() {
  const defaultForm = {
    mobile: "",
    code: "",
    loginType: localStorage.getItem("loginType") || "pwd",
    pwd: "",
  };
  const [open, setOpen] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>(defaultForm);

  const [status, setStatus] = React.useState({ validMobile: false, timer: 0 });
  React.useEffect(() => {
    const _sub = login.loginForm.subscribe(setOpen);
    return () => _sub.unsubscribe();
  }, []);

  function handleChange(e: IFormEvent) {
    const nf = Util.formChange(e, form);
    setForm(nf);
    setStatus({ ...status, validMobile: /^\d{11}$/.test(nf.mobile) });
  }

  function handleSentSms() {
    setStatus({ ...status, timer: 60 });
    const { mobile } = form;
    axios.post("open/smsVerification", { mobile }).then((r) => {
      if (r.data.code !== 0) {
        snk.next({ msg: `发送失败:${r.data?.msg}`, severity: "warning" });
      }
    });
  }

  function handleLogin() {
    const { loginType, code, mobile, pwd } = form;

    (loginType === "sn"
      ? axios.post("open/loginBySms", { code, mobile })
      : axios.post("open/loginByPwd", { pwd: SparkMD5.hash(pwd), mobile })
    ).then((r) => {
      if (!r.data) {
        snk.next({
          msg: (r as any)?.message || "登录失败",
          severity: "warning",
        });
      } else {
        snk.next({ msg: "登录成功" });
        setForm({ ...form, mobile: "", code: "", pwd: "" });
        setOpen(false);
        login.me = r.data;
        login.changed.next(r.data);
      }
      // cache.mycoop_fetch()
    });
  }

  React.useEffect(() => {
    if (status.timer > 0) {
      const id = setTimeout(
        () => setStatus({ ...status, timer: status.timer - 1 }),
        1000
      );
      return () => clearTimeout(id);
    }
    // eslint-disable-next-line
  }, [status.timer]);

  // 计时
  const [qrt, setQrt] = React.useState<number>(0);
  const [qrf, setQrf] = React.useState<boolean>(false);
  const [qrid, setQrid] = React.useState<string>("");
  React.useEffect(() => {
    localStorage.setItem("loginType", form.loginType);
    setQrf(false);
    let t: NodeJS.Timeout;
    let eventSource: EventSource;
    if (open && form.loginType === "wx") {
      axios.post("open/qrid", {}).then((r) => {
        const qrid = r.data?.id;
        setQrid(qrid);
        eventSource = new EventSource(`${APIHOST}login/${qrid}/${machine}`);
        eventSource.onmessage = ({ data }) => {
          try {
            if (JSON.parse(data).status === 1) {
              localStorage.setItem("token", JSON.parse(data)?.token);
              login.getMe();
              eventSource.close();
            }
          } catch (e) {}
        };
        t && clearTimeout(t);
        t = setTimeout(() => {
          setQrf(true);
          eventSource.close();
        }, 2000 * 60);
      });
    }
    return () => {
      t && clearTimeout(t);
      eventSource?.close();
    };
  }, [qrt, form.loginType, open]);

  const refrash = (
    <Stack
      onClick={() => setQrt(qrt + 1)}
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        bgcolor: "#9e9e9edb",
        justifyContent: "center",
        fontWeight: "bold",
        cursor: "pointer",
      }}
      textAlign={"center"}>
      二维码过期, 点击刷新
    </Stack>
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (login.me) {
          setOpen(false);
        }
      }}>
      <DialogTitle>
        {
          { sn: "验证码登录", pwd: "密码登录", wx: "微信扫码" }[
            form.loginType as string
          ]
        }
      </DialogTitle>
      <DialogContent>
        {form.loginType === "sn" && (
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
              <FormText
                name="mobile"
                value={form.mobile}
                label="手机号"
                onChange={handleChange}
              />
              <Button
                disabled={!status.validMobile || status.timer > 0}
                onClick={handleSentSms}>
                <Stack>
                  <Typography>发送验证码</Typography>
                  {status.timer > 0 && (
                    <Typography variant="caption">{status.timer}</Typography>
                  )}
                </Stack>
              </Button>
            </Stack>
            <Stack direction="row" spacing={1}>
              <FormText
                name="code"
                value={form.code}
                label="验证码"
                onChange={handleChange}
              />
              <Button
                variant="contained"
                disabled={!status.validMobile || !form.code}
                onClick={handleLogin}>
                登录
              </Button>
            </Stack>
            {/* {buttons()} */}
          </Stack>
        )}
        {form.loginType === "pwd" && (
          <form>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <FormText
                name="mobile"
                value={form.mobile}
                label="手机号"
                onChange={handleChange}
              />
              <FormText
                type="password"
                name="pwd"
                value={form.pwd}
                label="密码"
                onChange={handleChange}
              />
              <Button
                variant="contained"
                disabled={!status.validMobile || !form.pwd}
                onClick={handleLogin}>
                登录
              </Button>
            </Stack>
          </form>
        )}
        {form.loginType === "wx" && (
          <Stack sx={{ position: "relative" }}>
            <Stack sx={{ width: 200, height: 200 }} justifyContent="center">
              <LoginFormQr qrid={qrid} />
            </Stack>
            {qrf && refrash}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={form.loginType === "pwd"}
          size="small"
          onClick={() => setForm({ ...form, loginType: "pwd" })}>
          密码登录
        </Button>
        <Button
          disabled={form.loginType === "wx"}
          size="small"
          onClick={() => setForm({ ...form, loginType: "wx" })}>
          微信登录
        </Button>
        <Button
          disabled={form.loginType === "sn"}
          size="small"
          onClick={() => setForm({ ...form, loginType: "sn" })}>
          验证码登录
        </Button>
      </DialogActions>
    </Dialog>
  );
}
