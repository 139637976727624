import React from "react";
import qrcode from "qrcode";
import Loading from "./loading";
import { Appinfo } from "../common/config";

export default function LoginFormQr(props: { qrid: string }) {
  const { qrid } = props;
  const [src, setSrc] = React.useState<string>();

  React.useEffect(() => {
    const jumpdata = encodeURIComponent(
      JSON.stringify({
        ac: "login",
        qrid,
        appinfo: Appinfo,
      })
    );
    (async () => {
      setSrc(
        await qrcode.toDataURL(
          `https://api.yourdata.plus/jump/to?data=${jumpdata}`
        )
      );
    })();
  }, [qrid]);

  return src ? <img alt="" src={src} /> : <Loading variant="circular" />;
}
