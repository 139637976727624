import { Close, Delete } from "@mui/icons-material";
import {
  Stack,
  Typography,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Pagination,
} from "@mui/material";
import React from "react";
import { Ivoucher } from "../finance/finance.interface";
import { Ifinance } from "../interface";
import { APIHOST } from "../services/api.service";
import Util from "../services/util";
import VoucherInvoice from "./VoucherInvoice";

export default function VoucherFormAtta(props: {
  form: Ivoucher;
  setForm: (value: React.SetStateAction<Ivoucher>) => void;
  finance: Ifinance;
}) {
  const { form, setForm, finance } = props;
  const [atta, setAtta] = React.useState<boolean>(false);
  const [view, setView] = React.useState<number>(-1);

  return (
    <React.Fragment>
      <Stack>
        <Typography>附件:</Typography>
        <Stack direction="row" spacing={2}>
          {form.atta.map((a, aindex) => (
            <Card key={aindex}>
              <CardMedia
                onClick={() => setView(aindex)}
                sx={{ height: 100, width: 140 }}
                component="img"
                image={`${APIHOST}finance-files/${
                  finance.orgId
                }/${finance._id!}/${a}`}></CardMedia>
            </Card>
          ))}
          <Card onClick={() => setAtta(true)}>
            <CardMedia
              sx={{ height: 100, opacity: 0.5 }}
              component="img"
              image="/img/Add.svg"></CardMedia>
          </Card>
        </Stack>
      </Stack>
      {atta && (
        <Dialog open={true} maxWidth="lg" onClose={() => setAtta(false)}>
          <VoucherInvoice
            onSelect={(e) => {
              Util.pushToArray(form.atta, [e.md5, e.exName].join("."), false);
              setForm({ ...form });
              setAtta(false);
            }}
          />
        </Dialog>
      )}
      {view > -1 && (
        <Dialog open={true} fullScreen={true}>
          <DialogTitle>
            <Stack direction="row">
              <Stack flexGrow={1}>预览</Stack>
              <IconButton onClick={() => setView(-1)}>
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}>
              <img
                style={{ maxWidth: "100%", maxHeight: "100vh" }}
                alt="{view}"
                src={`${APIHOST}finance-files/${
                  finance.orgId
                }/${finance._id!}/${form.atta[view]}`}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="center">
              <Pagination
                page={view + 1}
                count={form.atta.length}
                onChange={(e, p) => {
                  setView(p - 1);
                }}
              />
              <IconButton
                onClick={() => {
                  form.atta.splice(view, 1);
                  setForm({ ...form });
                  setView(form.atta.length > 0 ? 0 : -1);
                }}>
                <Delete />
              </IconButton>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
