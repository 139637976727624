import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { financeMenu, Imenu, orgMenu, systemMenu } from "./HomeMenu";
import { Icontext } from "./interface";
import { login, orgAndMyinfo, progress } from "./services/app.service";
import { fetchFinance } from "./services/financeFuns";
import { my } from "./services/my.service";
import Util from "./services/util";
import Logo from "./ui/logo";
import MyToolbar from "./widgets/MyToolbar";

export default function Home() {
  const [context, setContext] = React.useState<Icontext>({
    me: login.me,
    orgId: localStorage.getItem("orgId") || "",
    financeId: localStorage.getItem("financeId") || "",
  });

  async function handleContext() {
    const { org, myinfo } = orgAndMyinfo({
      me: login.me,
      orgId: context.orgId,
      orgs: my.orgs,
    });
    const orgId =
      !org && my.orgs?.length ? my.orgs[0]?._id || "" : org?._id || "";
    localStorage.setItem("orgId", orgId);
    const c = {
      ...context,
      me: login.me,
      orgs: my.orgs,
      orgId,
      org,
      myinfo,
    };
    if (org?._id && context.financeId && !context.finance) {
      const finance = await fetchFinance(context.financeId, org._id);
      setContext({ ...c, finance });
    } else {
      setContext(c);
    }
  }

  React.useEffect(() => {
    if (!localStorage.getItem("token")) {
      login.loginForm.next(true);
    }
    login.changed.subscribe((r) => {
      handleContext();
      login.loginForm.next(!login.me);
    });
    if (!login.changed.observed && !login.me) {
      login.getMe();
    }
    my.orgsSub.subscribe(handleContext);

    handleContext();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (context.financeId) {
    }
  }, [context.financeId]);

  const locationArr = useLocation().pathname.split("/");
  const showSide = locationArr[2] !== "orgSetting";

  return context.me?._id ? (
    <Box>
      <MyToolbar context={context} />
      {showSide && <HomeSide context={context} />}
      <Stack sx={showSide ? { ml: "200px" } : {}}>
        <Toolbar />
        <Stack sx={{ mb: 10 }}>
          <Outlet context={[context, setContext]}></Outlet>
        </Stack>
      </Stack>
    </Box>
  ) : (
    <Stack
      sx={{
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
      }}
      onClick={() => {
        !progress.value && login.loginForm.next(true);
      }}>
      <Stack
        style={{ opacity: 0.2 }}
        flexGrow={1}
        alignItems="center"
        justifyContent="center">
        <Logo></Logo>
      </Stack>
      <Typography color="text.secondary" textAlign={"center"}>
        用户未登录
      </Typography>
    </Stack>
  );
}

//=============================================================={ HomeSide }
function HomeSide(props: { context: Icontext }): JSX.Element {
  const { context } = props;
  const locationArr = useLocation().pathname.split("/");
  const navigate = useNavigate();
  const [menu, setMenu] = React.useState<{
    sys: Imenu[];
    org: Imenu[];
    fin: Imenu[];
  }>();

  React.useEffect(() => {
    const _r = (i: Imenu) => !i.roles || i.roles.length === 0;
    const sys: Imenu[] = systemMenu.filter(
      (i) => _r(i) || Util.intersection(i.roles!, context.me!.roles).length
    );

    const myOrgRoles =
      context.org?.members.find((o) => o.on && o._id === context.me?._id)
        ?.roles || [];

    const org: Imenu[] = context.org
      ? orgMenu.filter((i) => {
          return _r(i) || Util.intersection(i.roles!, myOrgRoles).length;
        })
      : [];

    const myFinRoles =
      context.finance?.members?.find((o) => o.on && o._id === context.me?._id)
        ?.roles || [];

    const fin: Imenu[] = context.finance
      ? financeMenu.filter((i) => {
          return _r(i) || Util.intersection(i.roles!, myFinRoles).length;
        })
      : [];
    fin.forEach((i) => {
      i.children = i.children?.filter((a) => {
        return _r(a) || Util.intersection(a.roles!, myFinRoles).length;
      });
    });
    setMenu({ sys, org, fin });
  }, [context]);

  return (
    <Stack
      sx={{
        width: 200,
        position: "fixed",
        height: "100vh",
        backgroundColor: "#9e9e9e30",
      }}>
      <Toolbar />
      {menu && (
        <Stack sx={{ height: "100%", overflow: "auto" }}>
          {MyList(menu.fin, navigate, locationArr, "财务")}
          {MyList(menu.org, navigate, locationArr, "企业")}
          {MyList(menu.sys, navigate, locationArr, "系统")}
        </Stack>
      )}
    </Stack>
  );
}

function MyList(
  menu: Imenu[],
  navigate: any,
  locationArr: string[],
  caption: string
) {
  return (
    <React.Fragment>
      {!!menu.length && (
        <Stack>
          <Divider />
          <Typography variant="caption" sx={{ m: 1 }}>
            {caption}
          </Typography>
        </Stack>
      )}
      <List disablePadding={true}>
        {menu.map((i, index) => (
          <React.Fragment key={index}>
            <ListItem
              disablePadding={true}
              onClick={() => {
                navigate(i.name);
              }}>
              <ListItemButton selected={locationArr[2] === i.name}>
                {i.icon && (
                  <ListItemIcon>
                    {locationArr[2] === i.name
                      ? React.cloneElement(i.icon, { color: "primary" })
                      : i.icon}
                  </ListItemIcon>
                )}
                <ListItemText primary={i.title} />
              </ListItemButton>
            </ListItem>
            {i.children && locationArr[2] === i.name && (
              <List sx={{ pl: 2, bgcolor: "action.disabledBackground" }}>
                {i.children.map((ii, iindex) => {
                  const _selected = (locationArr[3] || "") === ii.name;
                  return (
                    <ListItem
                      key={iindex}
                      disablePadding={true}
                      sx={
                        _selected
                          ? {
                              bgcolor: "background.paper",
                              borderLeftWidth: 10,
                              borderLeftStyle: "solid",
                              borderLeftColor: (e) => {
                                return e.palette.primary.main;
                              },
                            }
                          : {}
                      }
                      onClick={() => {
                        navigate([i.name, ii.name].join("/"));
                      }}>
                      <ListItemButton>
                        {/* <ListItemIcon sx={{ minWidth: "2.5rem", ml: "1rem" }}>
                          {_selected && ii.icon
                            ? React.cloneElement(ii.icon, { color: "primary" })
                            : ii.icon}
                        </ListItemIcon> */}
                        <ListItemText
                          primaryTypographyProps={
                            _selected ? { color: "primary" } : {}
                          }
                          primary={ii.title}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );
}
