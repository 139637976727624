import { ContentPasteGo, Delete, Edit, Upload } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React from "react";
import { useOutletContext } from "react-router-dom";
import SparkMD5 from "spark-md5";
import { mOpttions, yOptions } from "../finance/finance.funs";
import { IFormEvent } from "../form/Form.interface";
import FormKeyword from "../form/FormKeyword";
import FormSelect from "../form/FormSelect";
import FormTags from "../form/FormTags";
import FormText from "../form/FormText";
import { IcontextStatue } from "../interface";
import { APIHOST } from "../services/api.service";
import Util from "../services/util";
import DataCommon from "../ui/DataCommon";

export default function VoucherInvoice(props: { onSelect?: (e: any) => void }) {
  const [context] = useOutletContext() as IcontextStatue;
  const { finance, orgId, financeId } = context;
  const [query, setQuery] = React.useState({
    limit: 20,
    skip: 0,
    keyword: "",
    y: Util.ymd()[0],
    m: Util.ymd()[1],
  });
  const [data, setData] = React.useState<{ data: any[]; count: number }>();
  const [form, setForm] = React.useState<{
    tags: string[];
    files: {
      exName: string;
      file: string;
      lastModified: number;
      md5: string;
      name: string;
      size: number;
    }[];
  }>();
  const [edit, setEdit] = React.useState<{
    anchorEl: HTMLButtonElement;
    name: string;
    tags: string[];
    _id: string;
  }>();

  function find() {
    (async () => {
      const { data } = await axios.post("finance/findInvoice", {
        orgId,
        financeId,
        where: {
          tags: query.keyword ? { $regex: query.keyword } : undefined,
          at: {
            $gte: new Date(query.y, query.m || 0).valueOf(),
            $lt: new Date(query.y, (query.m || 11) + 1, 1).valueOf(),
          },
        },
        skip: query.skip,
        limit: query.limit,
      });
      setData(data || { data: [], count: 0 });
    })();
  }
  // eslint-disable-next-line
  React.useEffect(find, [query]);

  function handleQueryChange(e: IFormEvent) {
    setQuery(Util.formChange(e, query));
  }

  return finance ? (
    <Stack sx={{ p: 2 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <FormSelect
          label="年"
          name="y"
          value={query.y}
          options={yOptions(finance.beginAt.y!)}
          onChange={handleQueryChange}
        />
        <FormSelect
          label="月"
          name="m"
          value={query.m}
          options={[
            { title: "全部", value: "" },
            ...mOpttions(query.y, finance.beginAt.date),
          ]}
          onChange={handleQueryChange}
        />
        <FormKeyword
          label="搜索"
          name="keyword"
          value={query.keyword}
          onChange={handleQueryChange}
        />
        <Stack flexGrow={1}></Stack>
        <Button
          variant="contained"
          startIcon={<Upload />}
          onClick={() => setForm({ files: [], tags: [] })}>
          上传
        </Button>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {data?.data.map((i, index) => (
          <Grid item md={2} sm={4} key={index}>
            <Card>
              <CardMedia
                onClick={() => props.onSelect && props.onSelect(i)}
                sx={{ height: 100 }}
                component="img"
                image={
                  ["png", "jpg", "gif", "pdf", "bmp", "jpeg", "webm"].includes(
                    i.exName
                  )
                    ? `${APIHOST}finance-files/${i.orgId}/${i.financeId}/${i.md5}.${i.exName}`
                    : ""
                }></CardMedia>
              <CardContent sx={{ pb: 0 }}>
                <Stack>
                  <Typography variant="caption">{i.name}</Typography>
                  <Typography
                    variant="caption"
                    sx={{ overflow: "hidden", height: "1rem" }}>
                    {i.tags}
                  </Typography>
                </Stack>
                {edit && (
                  <Popover
                    open={true}
                    anchorEl={edit?.anchorEl}
                    onClose={() => setEdit(undefined)}>
                    <Stack sx={{ p: 2 }} spacing={2}>
                      <FormText
                        label="标题"
                        name="name"
                        value={edit.name}
                        onChange={(e: IFormEvent) => {
                          setEdit(Util.formChange(e, edit));
                        }}
                      />
                      <FormTags
                        name="tags"
                        value={edit.tags}
                        label="标签"
                        onChange={(e: IFormEvent) => {
                          setEdit(Util.formChange(e, edit));
                        }}
                      />
                      <Stack direction="row-reverse">
                        <Button
                          onClick={async () => {
                            await axios.post("finance/saveInvoice", {
                              orgId,
                              financeId,
                              name: edit.name,
                              tags: edit.tags,
                              _id: edit._id,
                            });
                            setEdit(undefined);
                            find();
                          }}>
                          保存
                        </Button>
                      </Stack>
                    </Stack>
                  </Popover>
                )}
              </CardContent>
              <Stack direction="row-reverse">
                <IconButton
                  onClick={(e) =>
                    setEdit({
                      anchorEl: e.currentTarget,
                      tags: i.tags,
                      name: i.name,
                      _id: i._id,
                    })
                  }>
                  <Edit />
                </IconButton>
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>
      <DataCommon data={data} query={query} setQuery={setQuery} />
      {form && (
        <Dialog open={true}>
          <DialogTitle>上传</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  startIcon={<Upload />}
                  onClick={upload}>
                  上传文件
                </Button>
                <Button
                  variant="contained"
                  startIcon={<ContentPasteGo />}
                  onClick={paste}>
                  粘贴
                </Button>
              </Stack>
              <FormTags
                name={"tags"}
                value={form.tags}
                label={"标签"}
                onChange={handleFormChange}
              />
            </Stack>
            <Card>
              <CardContent>
                {form.files?.map((i: any, index: number) => (
                  <Stack key={index} direction="row" alignItems="center">
                    <Typography variant="caption">
                      {i.name} {(i.size / 1000).toFixed(2)}KB{" "}
                      {format(i.lastModified, "y-M-d h:m")}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        form.files.splice(index, 1);
                        setForm({ ...form });
                      }}>
                      <Delete />
                    </IconButton>
                  </Stack>
                ))}
              </CardContent>
            </Card>
            <DialogActions>
              <Button onClick={() => setForm(undefined)}>取消</Button>
              <Button
                disabled={!form.files.length}
                variant="contained"
                onClick={handleSave}>
                确定
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </Stack>
  ) : null;

  async function handleSave() {
    if (!form) return;
    await Promise.all(
      form.files.map(async (i) => {
        return await axios.post("finance/upInvoice", {
          ...i,
          exName: i.exName.toLowerCase(),
          tags: form.tags,
          at: Date.now(),
          orgId,
          financeId,
        });
      })
    );
    setForm(undefined);
    find();
  }

  function handleFormChange(e: IFormEvent) {
    setForm(Util.formChange(e, form));
  }

  async function paste(e: any) {
    try {
      const clipboardItems = await navigator.clipboard.read();
      for (const clipboardItem of clipboardItems) {
        for (const type of clipboardItem.types) {
          const blob = await clipboardItem.getType(type);
          const ex = type.split("/")[1];
          if (ex === "plain" || ex === "html") return;
          const base64 = await Util.tobase64(blob as File);
          if (base64) {
            form!.files.push({
              lastModified: Date.now(),
              name: "粘贴的" + ex,
              exName: ex.toLowerCase(),
              size: blob.size,
              file: base64,
              md5: SparkMD5.hash(base64),
            });
            setForm({ ...form! });
          }
        }
      }
    } catch (e) {}
  }

  async function upload() {
    if (!showOpenFilePicker) return;
    const res = await showOpenFilePicker({ multiple: true });
    const files = await Promise.all(
      res.map(async (f) => {
        const file = await f.getFile();
        const { lastModified, name, size } = file;
        const base64 = await Util.tobase64(file);
        const md5 = SparkMD5.hash(base64);
        return {
          lastModified,
          name,
          size,
          exName: name.split(".").at(-1) || "",
          file: base64,
          md5,
        };
      })
    );
    form!.files.push(...files);
    setForm({ ...form! });
  }
}
