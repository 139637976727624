import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { voucherTypeOptions } from "../common/config";
import { Ivoucher, IvoucherItem } from "../finance/finance.interface";
import { IFormEvent } from "../form/Form.interface";
import FormDate from "../form/FormDate";
import FormSelect from "../form/FormSelect";
import FormText from "../form/FormText";
import { IcontextStatue } from "../interface";
import { login } from "../services/app.service";
import Util from "../services/util";
import VoucherFormAtta from "./VoucherFormAtta";
import VoucherWidgetCodeInput from "./VoucherWidgetCodeInput";
import VoucherWidgetInput from "./VoucherWidgetInput";
import VoucherWidgetInputTitle from "./VoucherWidgetInputTitle";
import VoucherWidgetLine from "./VoucherWidgetLine";

export default function VoucherForm(props: {
  item: Ivoucher;
  onClose: () => void;
}) {
  const [context] = useOutletContext() as IcontextStatue;
  const { finance } = context;
  const [y, m, d] = Util.ymd();
  const { onClose, item } = props;
  const [flag, setFlag] = React.useState<{
    rows: number;
    invalid: string;
    data?: Ivoucher;
  }>({ rows: 6, invalid: "" });

  const [form, setForm] = React.useState<Ivoucher>({
    ...{
      orgId: context.orgId!,
      financeId: context.financeId!,
      voucherNo: 0,
      atta: [],
      total: { de: 0, cr: 0, _de: 0, _cr: 0 },
      voucherType: "记",
      at: { y, m, d, date: new Date(y, m, d).valueOf() },
      items: [],
      sign: { make: login.me?._id },
      attr: { status: 0, auto: false, initial: false }
    },
    ...item,
  });

  React.useEffect(() => {
    !item._id && maxVoucherNo();
    // eslint-disable-next-line
  }, [form?.at.date]);
  React.useEffect(() => {
    setForm(rowsChange());
    // eslint-disable-next-line
  }, [flag.rows]);
  // eslint-disable-next-line
  React.useEffect(() => budData(), [form]);

  function handleFormChange(e: IFormEvent) {
    const nf = Util.formChange(e, form);
    setForm(nf);
  }

  if (!form) return null;
  return (
    <Dialog open={true} onClose={onClose} fullScreen>
      <DialogTitle>
        <Stack direction="row" alignItems={"center"}>
          <Stack>{form._id ? "修改" : "添加"}</Stack>
          <Stack flexGrow={1}></Stack>
          <VoucherWidgetLine setFlag={setFlag} flag={flag} />
          <Stack flexGrow={1}></Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            {flag.invalid && (
              <Alert severity="warning" sx={{ pt: 0, pb: 0 }}>
                {flag.invalid}
              </Alert>
            )}
            <Button
              disabled={!!flag.invalid}
              variant="contained"
              onClick={handleSave}>
              保存
            </Button>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{ maxWidth: 1433, mt: 2, ml: "auto", mr: "auto" }}
          spacing={2}>
          <Stack direction="row" spacing={1}>
            <FormSelect
              label="类型"
              name="voucherType"
              value={form.voucherType}
              options={voucherTypeOptions}
              onChange={handleFormChange}
            />
            <FormText
              variant="filled"
              label="凭证号"
              name="voucherNo"
              value={form.voucherNo}
              onChange={handleFormChange}
            />
            <FormDate
              variant="filled"
              minDate={finance?.beginAt.date}
              maxDate={Date.now()}
              name={"at.date"}
              value={form.at.date}
              label={"日期"}
              onChange={handleFormChange}
            />
          </Stack>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  height: "1px",
                  "& th": {
                    border: "1px solid #9e9e9e30",
                    p: 0,
                    textAlign: "center",
                  },
                }}>
                <TableCell sx={{ width: "20rem" }}>摘要</TableCell>
                <TableCell sx={{ width: "20rem" }}>科目</TableCell>
                <TableCell sx={{ width: "13rem" }}>
                  <Stack alignItems="center">借方金额</Stack>
                  <Divider />
                  <VoucherWidgetInputTitle />
                </TableCell>
                <TableCell sx={{ width: "13rem" }}>
                  <Stack alignItems="center">贷方金额</Stack>
                  <Divider />
                  <VoucherWidgetInputTitle />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ "& td": { p: 0 } }}>
              {form.items.map((i, index) => (
                <TableRow
                  key={index}
                  sx={{
                    height: "1px",
                    "& td": { border: "1px solid #9e9e9e30" },
                  }}>
                  <TableCell>
                    <FormText
                      sx={{ fieldset: { border: 0 }, width: "100%" }}
                      name={`items.${index}.title`}
                      value={form.items[index].title}
                      placeholder="摘要"
                      onChange={handleFormChange}
                    />
                  </TableCell>
                  <TableCell>
                    <VoucherWidgetCodeInput
                      code={i.code || ""}
                      finance={finance!}
                      onChange={(value) => {
                        handleFormChange({
                          target: { name: `items.${index}.code`, value },
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ height: "inherit" }}>
                    <VoucherWidgetInput
                      value={i._de}
                      onChange={(value) => {
                        handleFormChange({
                          target: { name: `items.${index}._de`, value },
                        });
                      }}
                      onAction={handleAction(i, index, true)}
                    />
                  </TableCell>
                  <TableCell sx={{ height: "inherit" }}>
                    <VoucherWidgetInput
                      value={i._cr}
                      onChange={(value) => {
                        handleFormChange({
                          target: { name: `items.${index}._cr`, value },
                        });
                      }}
                      onAction={handleAction(i, index, false)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ height: "1px", "& *": { fontWeight: "bold" } }}>
                <TableCell
                  colSpan={2}
                  sx={{ borderLeft: "1px solid #9e9e9e30" }}>
                  合计: {Util.toMoneyCn((flag.data?.total._de || 0) / 100)}
                </TableCell>
                <TableCell sx={{ p: 0, height: "inherit" }}>
                  <VoucherWidgetInput
                    value={flag.data?.total._de}
                    readOnly={true}
                  />
                </TableCell>
                <TableCell sx={{ p: 0, height: "inherit" }}>
                  <VoucherWidgetInput
                    value={flag.data?.total._cr}
                    readOnly={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}>
                  制单:
                  {finance!.members.find((x) => x._id === form.sign.make)?.name}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Stack>
        <VoucherFormAtta form={form} setForm={setForm} finance={finance!} />
      </DialogContent>
    </Dialog>
  );

  async function maxVoucherNo() {
    if (!form) return;
    const [y, m] = Util.ymd(form.at.date);
    const { data } = await axios.post("finance/maxVoucherNo", {
      orgId: context.orgId,
      financeId: context.financeId,
      y,
      m,
    });
    setForm({ ...form, voucherNo: (data?.voucherNo || 0) + 1 });
  }

  function rowsChange() {
    const _diff = flag.rows - form.items.length;
    let items = form.items.slice();
    if (_diff > 0) {
      const _n: IvoucherItem[] = Array(_diff)
        .fill("")
        .map((i) => ({
          title: "",
          code: "",
          cr: 0,
          de: 0,
          _cr: 0,
          _de: 0,
          currency: "",
          supplementary: {
            id: [],
          },
        }));
      items.push(..._n);
    } else if (_diff < 0) {
      items = items.slice(0, _diff);
    }
    form.items = items;
    return form;
  }

  // 转换汇率
  function loc(item: IvoucherItem): IvoucherItem {
    const cate = finance?.category.find((x) => x.code === item.code);
    const loc = finance?.currency.find((x) => x.isLocal)?._id || "RMB";
    if (!cate) return item;
    item.currency = cate.currency || loc;
    const rate =
      finance!.currency.find((x) => item.currency === x._id)?.rate || 1;
    item.de = item._de * rate;
    item.cr = item._cr * rate;
    return { ...item };
  }
  //  生成表单
  function budData() {
    const [y, m, d] = Util.ymd(form.at.date);
    const items = form.items
      .filter((i: { _de: any; _cr: any }) => i._de || i._cr)
      .map((i: IvoucherItem) => loc(i));
    const data: Ivoucher = {
      ...form,
      items,
      at: { ...form.at, y, m, d },
      total: {
        de: Util.sum(items.map((i: { de: any }) => i.de)),
        cr: Util.sum(items.map((i: any) => i.cr)),
        _de: Util.sum(items.map((i: any) => i._de)),
        _cr: Util.sum(items.map((i: any) => i._cr)),
      },
    };
    setFlag({
      ...flag,
      invalid: !data.items.length
        ? "未录入"
        : !data.total._de
          ? "无金额"
          : data.total._de !== data.total._cr
            ? "借贷不平"
            : data.items.length !== data.items.filter((x) => !!x.code).length
              ? "科目未输入"
              : "",
      data,
    });
  }

  function handleAction(
    i: IvoucherItem,
    index: number,
    de: boolean
  ): ((ac: any) => void) | undefined {
    return (ac) => {
      if (ac === "=") {
        const sumDe = Util.sum(form!.items.map((ii) => ii._de));
        const sumCr = Util.sum(form!.items.map((ii) => ii._cr));
        const value = de
          ? sumCr - sumDe + (i._de || 0)
          : sumDe - sumCr + (i._cr || 0);
        handleFormChange({
          target: {
            name: de ? `items.${index}._de` : `items.${index}._cr`,
            value,
          },
        });
      }
    };
  }

  async function handleSave() {
    const { orgId, financeId } = context;
    await axios.post("finance/saveVoucher", {
      orgId,
      financeId,
      _id: form!._id,
      data: flag.data,
    });
    onClose();
  }
}
