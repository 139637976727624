import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { IFormEvent } from "../form/Form.interface";
import FormKeyword from "../form/FormKeyword";
import FormSelect from "../form/FormSelect";
import { IMember } from "../interface";
import Util from "../services/util";
import DataCommon from "../ui/DataCommon";

export default function Member() {
  const [query, setQuery] = React.useState({
    keyword: "",
    roles: "",
    limit: 20,
    skip: 0,
  });
  const [data, setData] = React.useState<{ data: IMember[]; count: number }>();
  const [form, setForm] = React.useState<IMember>();

  function handleQueryChange(e: IFormEvent): void {
    query.skip = 0;
    setQuery(Util.formChange(e, query));
  }

  function find() {
    const r =
      query.roles === "!"
        ? { roles: { $size: 0 } }
        : !query.roles
        ? {}
        : { roles: query.roles };
    const k = query.keyword
      ? {
          $or: ["nickname", "mobile"].map((i) => ({
            [i]: { $regex: query.keyword },
          })),
        }
      : {};
    axios
      .post("find/member", {
        where: { ...r, ...k },
        skip: query.skip,
        limit: query.limit,
      })
      .then((r) => {
        setData(r.data || { count: 0, data: [] });
      });
  }
  React.useEffect(find, [query]);

  function handleFormChange(e: IFormEvent): void {
    setForm(Util.formChange(e, form));
  }

  return (
    <Stack sx={{ p: 2 }} spacing={2}>
      <Stack direction="row" spacing={2}>
        <FormSelect
          label="角色"
          name="roles"
          value={query.roles}
          options={[
            { title: "所有", value: "" },
            { title: "普通用户", value: "!" },
            { title: "管理员", value: "admin" },
          ]}
          onChange={handleQueryChange}></FormSelect>
        <FormKeyword
          label="搜索"
          name="keyword"
          value={query.keyword}
          onChange={handleQueryChange}></FormKeyword>
      </Stack>
      {data?.data && data.data.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>姓名</TableCell>
              <TableCell>电话</TableCell>
              <TableCell>角色</TableCell>
              <TableCell>状态</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.map((i, index) => (
              <TableRow key={index} onClick={() => setForm(i)}>
                <TableCell sx={{ p: 0, pl: 2 }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar
                      src={i.avatar}
                      alt={i.nickname}
                      sx={{ width: "2rem", height: "2rem" }}></Avatar>
                    <Stack>{i.nickname}</Stack>
                  </Stack>
                </TableCell>
                <TableCell>{i.mobile}</TableCell>
                <TableCell>
                  {i.roles
                    .map(
                      (ii: any) =>
                        (({ admin: "管理员", org: "企业" } as any)[ii])
                    )
                    .join(", ")}
                </TableCell>
                <TableCell sx={{ p: 0 }}>
                  {i.status === 1 ? (
                    <Chip color="secondary" label="锁定" />
                  ) : (
                    <Chip label="正常" />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <DataCommon data={data} query={query} setQuery={setQuery} />
      {form && (
        <Dialog open={true} onClose={() => setForm(undefined)}>
          <DialogTitle>用户设置</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Stack direction="row">
                用户: {form.nickname} 手机号: {form.mobile}
              </Stack>
              <FormSelect
                multiple={true}
                label="角色"
                name={"roles"}
                value={form.roles}
                onChange={handleFormChange}
                options={[{ title: "管理员", value: "admin" }]}></FormSelect>
              <Stack direction={"row"}>
                <FormSelect
                  label="状态"
                  name="status"
                  value={form.status}
                  onChange={handleFormChange}
                  options={[
                    { title: "正常", value: 0 },
                    { title: "锁定", value: 1 },
                  ]}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setForm(undefined)}>取消</Button>
            <Button
              variant="contained"
              onClick={() => {
                const { _id, roles, status } = form;
                axios
                  .post("save/member", {
                    where: { _id },
                    data: { $set: { roles, status } },
                  })
                  .then((r) => {
                    find();
                    setForm(undefined);
                  });
              }}>
              保存
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
}
