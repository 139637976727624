import { LinearProgress } from "@mui/material";
import React from "react";
import { progress } from "../services/app.service";

export function MyProgress() {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    progress.sub.subscribe(() => {
      const nv = !!progress.value;
      setShow(nv);
    });
  }, []);
  return show ? (
    <LinearProgress sx={{ position: "fixed", width: "100vw", zIndex: 9999 }} />
  ) : (
    <React.Fragment></React.Fragment>
  );
}
