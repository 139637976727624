import { Person } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";
import { login } from "../services/app.service";
import AccountName from "./AccountName";
import AccountPwd from "./AccountPwd";

export default function Account() {
  const [me, setMe] = React.useState(login.me);
  const [open, setOpen] = React.useState("");
  React.useEffect(() => {
    login.changed.subscribe(() => {
      setMe(login.me);
    });
  }, []);

  const handleClose = () => {
    setOpen("");
    setMe(login.me);
    console.log(0);
  };
  return (
    <Container sx={{ pt: 10 }}>
      {me ? (
        <Card sx={{ maxWidth: 300, m: "auto", mt: 10 }}>
          <CardContent>
            <Stack spacing={2}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                onClick={() => setOpen("name")}>
                <IconButton>
                  <Avatar
                    alt={me.nickname}
                    src={me.avatar || ""}
                    sx={{ fontSize: ".8rem" }}>
                    {me.avatar ? null : me.nickname ? (
                      me.nickname.substring(me.nickname.length - 2)
                    ) : (
                      <Person />
                    )}
                  </Avatar>
                </IconButton>
                <Button color="inherit">{me.nickname || "~无昵称"}</Button>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Stack flexGrow={1}>
                  密码: {me.password ? "已设置" : "未设置"}
                </Stack>
                <Button onClick={() => setOpen("pwd")}>设置</Button>
              </Stack>
            </Stack>
            {open === "pwd" && <AccountPwd onClose={handleClose}></AccountPwd>}
            {open === "name" && <AccountName onClose={handleClose} />}
          </CardContent>
        </Card>
      ) : (
        <Stack>未登录</Stack>
      )}
    </Container>
  );
}
