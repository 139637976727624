import axios from "axios";
import { Subject } from "rxjs";
import { Icontext } from "../interface";
import { Me } from "../widgets/shard.interface";

//==============================={ 用户登录 }
export const login: {
  me?: Me;
  loginForm: Subject<boolean>;
  changed: Subject<Me | undefined>;
  getMe: () => void;
} = {
  me: undefined,
  // 登录对话框
  loginForm: new Subject(),
  // 用户登录状态
  changed: new Subject(),
  getMe() {
    const token = localStorage.getItem("token");
    token &&
      axios
        .post("soft/getme", {})
        .then((r) => {
          login.me = r.data as Me;
          login.changed.next(r.data);
        })
        .catch((r) => {
          login.me = undefined;
          login.changed.next(undefined);
        });
  },
};

//==============================={ 颜色模式 }
export const colorMode = {
  isDark: window.matchMedia("(prefers-color-scheme: dark)").matches,
  sub: new Subject<boolean>(),
};

//==============================={ 提示 }
export type Snk = {
  open?: boolean;
  msg: string;
  severity?: "error" | "info" | "success" | "warning";
  autoHideDuration?: number;
}
export const snk = new Subject<Snk>();

export const progress = {
  value: 0,
  sub: new Subject<number>(),
};

export function orgAndMyinfo({ me, orgId, orgs }: Icontext) {
  // const { me, orgId, orgs } = context;
  const org = orgs?.find((i) => i._id === orgId);
  const myinfo = org?.members.find((x) => x.on && x._id === me?._id);
  return { org, myinfo };
}
