import { FormSelectOption } from "../form/Form.interface";
import { Icurrency } from "../interface";
import Util from "../services/util";
import { IinitialData, IvoucherItem } from "./finance.interface";

export function toLocalCurrency(
  currency: Icurrency[],
  value: number,
  ipt?: string
) {
  const _i = ipt && currency.find((i) => i._id === ipt);
  const _o = currency.find((i) => i.isLocal);
  if (_i && _o) {
    return _i.rate * value;
  } else {
    return value;
  }
}
/**
 * 期初合并数据 转凭证 items
 * @param initialData 期初合并数据
 * @param currency 币种设置
 * @param all 包含空值
 * @returns
 */
export function toItems(
  initialData: IinitialData[],
  currency: Icurrency[],
  all = false
): IvoucherItem[] {
  const res = initialData!.map((i) => ({
    title: "",
    code: i.cate.code,
    cr: toLocalCurrency(currency, i._cr, i._c),
    de: toLocalCurrency(currency, i._de, i._c),
    _cr: i._cr, //外币
    _de: i._de, //外币
    currency: i._c, // 外币名
    supplementary: {
      id: [],
    },
  }));
  return all ? res : res.filter((i) => i._cr || i._de);
}
/**
 * 年选项
 * @param min 最小
 * @returns
 */
export function yOptions(min: number): FormSelectOption[] {
  let [y] = Util.ymd();
  let flag = 0;
  const opt = [];
  while (y >= min && flag < 100) {
    opt.push({ value: y, title: `${y}` });
    y--;
    flag++;
  }
  return opt;
}

export function mOpttions(
  y: number,
  minDate: number,
  maxDate: number = Date.now()
) {
  const _m = Array(12)
    .fill(0)
    .map((i, index) => ({ value: index, title: `${index + 1}` }));
  return _m.filter((i) => {
    const c = new Date(y, i.value).valueOf();
    return c >= minDate && c <= maxDate;
  });
}
