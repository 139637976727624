import { Add } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns/esm";
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { IcontextStatue } from "../interface";
import OrgMembers from "./OrgMembers";

export default function OrgMain() {
  const [{ org }] = useOutletContext() as IcontextStatue;
  const navigate = useNavigate();
  const newMember = React.useState(false);

  return org ? (
    <Stack sx={{ p: 2 }} spacing={2}>
      <Typography>{org?.name}</Typography>
      <Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card>
              <CardHeader title="信息" />
              <CardContent>
                <Stack spacing={1}>
                  <Stack>
                    注册时间: {format(new Date(org.createdAt!), "y年M月d日")}
                  </Stack>
                  <Stack>到期时间: {format(org.expired, "y年M月d日")}</Stack>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardHeader title="账套" />
              <CardContent>
                <Stack spacing={1}>
                  <Stack>
                    账套数: {org.nowFinance || 0} / {org.maxFinance}
                  </Stack>
                  <Button onClick={() => navigate("../orgSetting")}>
                    账套管理
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="用户"
                subheader={`共有${org.members.length}用户
               启用${org.members.filter((i) => i.on).length}
              管理员${
                org.members.filter((i) => i.on && i.roles.includes("admin"))
                  .length
              }`}
                action={
                  <IconButton onClick={() => newMember[1](true)}>
                    <Add />
                  </IconButton>
                }
              />
              <CardContent>
                <OrgMembers newMember={newMember} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  ) : null;
}
