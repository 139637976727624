import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import SparkMD5 from "spark-md5";
import { IFormEvent } from "../form/Form.interface";
import FormText from "../form/FormText";
import { login, snk } from "../services/app.service";
import Util from "../services/util";

export default function AccountPwd(props: { onClose: (e?: any) => void }) {
  const [form, setForm] = React.useState({
    mobile: "",
    pwd: "",
    code: "",
    invalid: true,
    inputType: "password",
  });
  const [timer, setTimer] = React.useState(0);

  function handleChange(e: IFormEvent) {
    const nf = Util.formChange(e, form);
    const invalid = !(Util.checkMobileNum(nf.mobile) && nf.pwd.length > 5);
    setForm({ ...nf, invalid });
  }

  React.useEffect(() => {
    if (timer > 0) {
      const id = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(id);
    }
    // eslint-disable-next-line
  }, [timer]);

  function handleSentSms() {
    setTimer(60);
    const { mobile } = form;
    axios.post("open/smsVerification", { mobile }).then((r) => {
      if (r.data.code !== 0) {
        snk.next({ msg: `发送失败:${r.data?.msg}`, severity: "warning" });
      }
    });
  }

  function handleSave() {
    if (!login.me?._id) return;
    const { code, mobile, pwd } = form;
    axios
      .post("open/accountSet", {
        code,
        mobile,
        pwd: SparkMD5.hash(pwd),
        _id: login.me._id,
      })
      .then((r) => {
        if (r?.data._id) {
          login.me = r.data;
          login.changed.next(login.me);
          props.onClose();
        }
      });
  }

  return (
    <Dialog open={true} onClose={() => props.onClose()}>
      <DialogTitle>设置密码</DialogTitle>
      <DialogContent>
        <form name="changePwd">
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack spacing={2}>
              <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                <FormText
                  name="mobile"
                  value={form.mobile}
                  label="手机号"
                  onChange={handleChange}
                />
                <Button
                  disabled={!Util.checkMobileNum(form.mobile)}
                  onClick={handleSentSms}>
                  <Stack>
                    <Typography>发送验证码</Typography>
                    {timer > 0 && (
                      <Typography variant="caption">{timer}</Typography>
                    )}
                  </Stack>
                </Button>
              </Stack>
              <Stack direction="row" spacing={1}>
                <FormText
                  name="code"
                  value={form.code}
                  label="验证码"
                  onChange={handleChange}
                />
              </Stack>
            </Stack>
            <FormText
              type={form.inputType}
              label="设置密码"
              name="pwd"
              value={form.pwd}
              onChange={handleChange}
              helperText="6位以上区分大小写"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setForm({
                          ...form,
                          inputType:
                            form.inputType === "text" ? "password" : "text",
                        })
                      }>
                      {form.inputType === "text" ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}></FormText>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>取消</Button>
        <Button
          disabled={form.invalid}
          variant="contained"
          onClick={() => handleSave()}>
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
}
