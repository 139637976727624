import { Autocomplete, SxProps, TextField, TextFieldProps, Theme } from "@mui/material";
import React from "react";
import { Ifinance, IfinanceCategory } from "../interface";
import { cateName } from "../services/financeFuns";
interface Ioption {
  label: string;
  id: string;
  py: string;
  _: IfinanceCategory;
  _pname: string | undefined;
}

export default function VoucherWidgetCodeInput(props: {
  code: string;
  finance: Ifinance;
  onChange?: (code: string) => void;
  textFieldProps?: TextFieldProps;
  all?: boolean;
  sx?: SxProps<Theme>
}) {
  const { code, finance, onChange, textFieldProps, all, sx } = props;

  const options: Ioption[] = (
    all
      ? finance.category
      : finance.category.filter((i) => !i._children?.length)
  ).map((i) => ({
    label: `${i.code}:${cateName(i.code, finance.category, true)}`,
    id: i.code,
    py: i.py,
    _: i,
    _pname: i._p?.name,
  }));

  const [value, setValue] = React.useState<Ioption | null>(options.find((i) => i.id === code) || null);
  const [error, setError] = React.useState<boolean>(false);
  React.useEffect(() => {

    if (value?.id !== code) {
      setValue(options.find((i) => i.id === code) || null)
    };
  }, [code])

  return (
    <Autocomplete
      sx={{ ...sx, p: 0 }}
      disablePortal
      isOptionEqualToValue={(x, o) => {
        return x?.id === o?.id;
      }}
      value={value}
      onChange={(e, v) => {
        const hasChildren = !!(v && (v._._children?.length || 0));
        setError(!all && hasChildren);
        onChange && onChange(!all && hasChildren ? "" : v?.id || "");
      }}
      renderInput={(params) => (
        <TextField
          sx={{ fieldset: { border: error ? 1 : 0 }, width: "100%" }}
          placeholder="科目"
          {...textFieldProps}
          {...params}
          error={error}
        />
      )}
      filterOptions={(o, { inputValue }) => {
        return o.filter(
          (i) =>
            i.label.includes(inputValue) ||
            i.py.includes(inputValue.toUpperCase()) ||
            i._._p?.name?.includes(inputValue) ||
            i._._p?.code?.includes(inputValue) ||
            i._._p?.py?.includes(inputValue.toUpperCase())
        );
      }}
      options={options}
    />
  );
}
