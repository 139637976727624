import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns/esm";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { voucherTypeOptions } from "../common/config";
import { mOpttions, yOptions } from "../finance/finance.funs";
import { Ivoucher, IvoucherUnwind } from "../finance/finance.interface";
import { IFormEvent } from "../form/Form.interface";
import FormKeyword from "../form/FormKeyword";
import FormSelect from "../form/FormSelect";
import { IcontextStatue } from "../interface";
import { cateName } from "../services/financeFuns";
import Util from "../services/util";
import DataCommon from "../ui/DataCommon";
import Excel from "../ui/icon/Excel";
import VoucherWidgetCodeInput from "../voucher/VoucherWidgetCodeInput";
import VoucherForm from "../voucher/VoucherForm";

export default function AccountsBookSubsidiaryLedger() {
  const [y, m] = Util.ymd();
  const [context] = useOutletContext() as IcontextStatue;
  const { finance, orgId, financeId } = context;
  const [query, setQuery] = React.useState({
    keyword: "",
    voucherType: "",
    code: "",
    sort: { "items.voucherNo": 1 },
    y,
    m,
    skip: 0,
    limit: 10,
  });
  const [data, setData] = React.useState<{
    data: IvoucherUnwind[];
    count: number;
  }>();
  const [form, setForm] = React.useState<Ivoucher>();

  async function find() {
    const { data } = await axios.post("finance/accountsBookSubsidiaryLedger", {
      orgId,
      financeId,
      where: {
        voucherType: query.voucherType || undefined,
        "at.y": query.y,
        "at.m": query.m,
        "items.title": query.keyword ? { $regex: query.keyword } : undefined,
        "items.code": query.code ? { $regex: "^" + query.code } : undefined,
      },
      fields: { at: 1, voucherType: 1, voucherNo: 1, code: 1, items: 1 },
      sort: query.sort,
      limit: query.limit,
      skip: query.skip,
    });
    setData(data);
  }

  React.useEffect(() => {
    find();
    // eslint-disable-next-line
  }, [query]);

  function handleQueryChange(e: IFormEvent) {
    setQuery(Util.formChange(e, query));
  }

  return finance ? (
    <Stack sx={{ p: 2 }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <FormSelect
          label="类型"
          name="voucherType"
          value={query.voucherType}
          options={[{ value: "", title: "全部" }, ...voucherTypeOptions]}
          onChange={handleQueryChange}
        />
        <FormSelect
          label="年"
          name="y"
          value={query.y}
          options={yOptions(finance.beginAt.y!)}
          onChange={handleQueryChange}
        />
        <FormSelect
          label="月"
          name="m"
          value={query.m}
          options={mOpttions(query.y, finance.beginAt.date)}
          onChange={handleQueryChange}
        />
        <FormKeyword
          label="搜索"
          name="keyword"
          value={query.keyword}
          onChange={handleQueryChange}
        />
        <Stack sx={{ width: "20rem" }}>
          <VoucherWidgetCodeInput
            textFieldProps={{ variant: "filled" }}
            all={true}
            code={query.code}
            finance={finance!}
            onChange={(value) => {
              handleQueryChange({
                target: { name: `code`, value },
              });
            }}
          />
        </Stack>
        <Stack flexGrow={1}></Stack>
        <Tooltip title={"导出"}>
          <IconButton
            onClick={toExcel}>
            <Excel />
          </IconButton>
        </Tooltip>
      </Stack>
      {data?.data && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>日期</TableCell>
              <TableCell>凭证字号</TableCell>
              <TableCell>科目</TableCell>
              <TableCell>摘要</TableCell>
              <TableCell>借方</TableCell>
              <TableCell>贷方</TableCell>
              <TableCell>方向</TableCell>
              <TableCell>余额</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.map((i, index) => {
              const isde = finance.category.find(
                (x) => x.code === i.items.code
              )?.de;
              return (
                <TableRow key={index} hover>
                  <TableCell>{format(i.at.date, "y年M月d日")}</TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <Button
                      onClick={async () => {
                        const { data } = await axios.post(
                          "finance/fetchVoucherById",
                          { orgId, financeId, _id: i._id }
                        );
                        setForm(data);
                      }}>
                      {i.voucherType}
                      {Util.preZero(i.voucherNo)}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {cateName(i.items.code, finance.category)}
                  </TableCell>
                  <TableCell>{i.items.title}</TableCell>
                  <TableCell>{Util.toMoney(i.items._de, true, "")}</TableCell>
                  <TableCell>{Util.toMoney(i.items._cr, true, "")}</TableCell>
                  <TableCell>{isde ? "借" : "贷"}</TableCell>
                  <TableCell>
                    {isde
                      ? Util.toMoney(i.items._de - i.items._cr, true, "")
                      : Util.toMoney(i.items._cr - i.items._de, true, "")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <DataCommon data={data} query={query} setQuery={setQuery} />
      {form && (
        <VoucherForm
          item={form}
          onClose={() => {
            setForm(undefined);
            find();
          }}></VoucherForm>
      )}
    </Stack>
  ) : null;

  async function toExcel() {
    const { data } = await axios.post(
      "finance/accountsBookSubsidiaryLedger",
      {
        orgId,
        financeId,
        where: {
          "at.y": query.y,
          "at.m": query.m,
        },
        fields: {
          at: 1,
          voucherType: 1,
          voucherNo: 1,
          code: 1,
          items: 1,
        },
        sort: query.sort,
        skip: 0,
        limit: -1,
      }
    );
    const sheet = data.data.map((i: IvoucherUnwind) => {
      const isde = finance!.category.find(
        (x) => x.code === i.items.code
      )?.de;
      return {
        日期: format(i.at.date, "y年M月d日"),
        凭证字号: i.voucherType + Util.preZero(i.voucherNo),
        科目编号: i.items.code,
        科目: cateName(i.items.code, finance!.category),
        摘要: i.items.title,
        借方: Util.toMoney(i.items._de, true, ""),
        贷方: Util.toMoney(i.items._cr, true, ""),
        方向: isde ? "借" : "贷",
        余额: isde
          ? Util.toMoney(i.items._de - i.items._cr, true, "")
          : Util.toMoney(i.items._cr - i.items._de, true, ""),
      };
    });
    Util.toXlsxFile(sheet, `明细账${query.y}年${query.m}月.xlsx`);
  }
}
