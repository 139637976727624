import { Checkbox, FormControlLabel } from "@mui/material";
import { FormProps } from "./Form.interface";

export default function FormCheckbox(props: FormProps) {
  const { name, label, onChange, value } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={value}
          onChange={(e, v) => {
            onChange({
              target: {
                name: name,
                value: v,
              },
            });
          }}
        />
      }
      label={label}
    />
  );
}
