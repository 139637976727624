import { Alert, Box, Button, Card, CardActions, CardContent, FormControl, Hidden, IconButton, Popover, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import Util from "../services/util";
import FormDate from "../form/FormDate";
import FormSelect from "../form/FormSelect";
import { IFormEvent } from "../form/Form.interface";
import { mOpttions, yOptions } from "../finance/finance.funs";
import { useOutletContext } from "react-router-dom";
import { IcontextStatue, IfinanceCategory } from "../interface";
import VoucherWidgetCodeInput from "../voucher/VoucherWidgetCodeInput";
import { cateName, maxCodeLengthOfFinanceCategory } from "../services/financeFuns";
import { ArrowDropDown, ArrowDropUp, TableRows } from "@mui/icons-material";
import axios from "axios";
import DataCommon from "../ui/DataCommon";
import Loading from "../ui/loading";

export default function GeneralLedger() {
  const [context] = useOutletContext() as IcontextStatue;
  const { finance, orgId, financeId } = context;

  const [showQuery, setShowQuery] = React.useState<boolean>(false)

  const [y, m, d] = Util.ymd()
  const [query, setQuery] = React.useState<{
    code: string;
    code1: string;
    y: number, m: number,
    y1: number, m1: number,
    time: string[];
    sort: string;
    skip: number;
    limit: number;
  }>({
    code: '', code1: '', y, m, y1: y, m1: m, time: [`${y}年${m}月`, `${y}年${m}月`], sort: 'voucherNo',
    skip: 0, limit: 20
  });


  function handleQueryChange(e: IFormEvent) {
    const { name, value } = e.target
    const newQuery = Util.formChange(e, query)
    if (name === 'code') {
      newQuery.code1 = !newQuery.code1 ? value : newQuery.code1
    }
    if (newQuery.code1 < newQuery.code) {
      if (name === 'code') { newQuery.code1 = newQuery.code }
      if (name === 'code1') { newQuery.code = newQuery.code1 }
    }
    if (newQuery.y1 < newQuery.y) {
      if (name === 'y') { newQuery.y1 = newQuery.y }
      if (name === 'y1') { newQuery.y = newQuery.y1 }
    }
    if (newQuery.y === newQuery.y1) {
      if (newQuery.m1 < newQuery.m) {
        if (name === 'm' || name === 'y') { newQuery.m1 = newQuery.m }
        if (name === 'm1' || name === 'y1') { newQuery.m = newQuery.m1 }
      }
    }
    const _mo = mOpttions(newQuery.y, finance!.beginAt.date)
    const _mo1 = mOpttions(newQuery.y1, finance!.beginAt.date)

    if (!_mo.find(i => i.value === newQuery.m)) {
      newQuery.m = _mo[0]?.value
    };
    if (!_mo1.find(i => i.value === newQuery.m1)) {
      newQuery.m1 = _mo1[0]?.value
    };

    setQuery(newQuery);
  }

  const [data, setData] = React.useState<{
    data: {
      code: string, codeName: string,
      y: number, m: number, cr: number, de: number,
      cate: IfinanceCategory
    }[],
    count: number
  }>()

  React.useEffect(find, [query])
  function find() {
    axios.post('finance/generalLedger', { ...query, orgId: finance?.orgId, financeId: finance?._id }).then(({ data }) => {
      const _d = data.data.map((i: any) => ({
        ...i,
        cate: finance?.category.find(a => a.code === i.code),
        codeName: cateName(i.code, finance!.category),
      }))
      setData({ data: _d, count: data.count })
    })

  }

  return finance ? <Stack sx={{ p: 2 }} spacing={2}>

    <Stack direction={"row"} alignItems={"center"}
      onClick={(e) => setShowQuery(!showQuery)}
    >
      <Stack direction={"row"} sx={{ cursor: "pointer", alignItems: 'center' }}>
        <Typography variant="caption">期间：</Typography>
        {`${query.y}年${query.m + 1}月`}
        {query.y1 !== query.y ? ` - ${query.y1}年${query.m1 + 1}月` : query.m1 !== query.m ? ` - ${query.m1 + 1}月` : ''}
        <Typography variant="caption" sx={{ ml: 2 }}>科目：</Typography>
        {query.code
          ? cateName(query.code, finance.category) +
          (query.code !== query.code1 ? ' ... ' + cateName(query.code1, finance.category) : '')
          : '所有'}
      </Stack>
      <IconButton >
        {showQuery ? <ArrowDropUp /> : <ArrowDropDown />}
      </IconButton>
    </Stack>

    {showQuery && <Card>
      <CardContent>
        <Stack spacing={2} >
          <Stack direction={"row"}>
            <VoucherWidgetCodeInput
              sx={{ flex: 1 }}
              textFieldProps={{ variant: "filled" }}
              all={true}
              code={query.code}
              finance={finance!}
              onChange={(value) => {
                handleQueryChange({
                  target: { name: `code`, value },
                });
              }}
            />
            <Stack justifyContent={"center"} sx={{ ml: 1, mr: 1 }}>至</Stack>
            <VoucherWidgetCodeInput
              sx={{ flex: 1 }}
              textFieldProps={{ variant: "filled" }}
              all={true}
              code={query.code1}
              finance={finance!}
              onChange={(value) => {
                handleQueryChange({
                  target: { name: `code1`, value },
                });
              }}
            />
          </Stack>
          <Stack direction={"row"} alignContent={"center"} spacing={1}
          >
            <FormSelect
              label="年"
              name="y"
              value={query.y}
              options={yOptions(finance.beginAt.y!)}
              onChange={handleQueryChange}
            />
            <FormSelect
              label="月"
              name="m"
              value={query.m}
              options={mOpttions(query.y, finance.beginAt.date)}
              onChange={handleQueryChange}
            />
            <Stack justifyContent={"center"}>至</Stack>
            <FormSelect
              label="年"
              name="y1"
              value={query.y1}
              options={yOptions(finance.beginAt.y!)}
              onChange={handleQueryChange}
            />
            <FormSelect
              label="月"
              name="m1"
              value={query.m1}
              options={mOpttions(query.y1, finance.beginAt.date)}
              onChange={handleQueryChange}
            />

          </Stack>
          {/* <Stack direction={"row"}>
            <FormSelect name="sort" label="排序" value={query.sort} onChange={handleQueryChange}
              options={[{ title: '凭证号', value: 'voucherNo' }, { title: '日期', value: 'at.date' }]} />
          </Stack> */}
          {/* <Stack sx={{height:20}}> </Stack> */}
        </Stack>
      </CardContent>
      <CardActions>
        <Button onClick={() => setShowQuery(false)} >收起</Button>
      </CardActions>
    </Card>}
    {data?.data && data.count > 0 && <Table>
      <TableHead>
        <TableRow>
          <TableCell>编码</TableCell>
          <TableCell>名称</TableCell>
          <TableCell>期间</TableCell>
          <TableCell>摘要</TableCell>
          <TableCell>借方</TableCell>
          <TableCell>贷方</TableCell>
          <TableCell>方向</TableCell>
          <TableCell>余额</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* {JSON.stringify(data)} */}
        {data.data.map((i, index) => <TableRow key={index}>
          <TableCell>{i.code}</TableCell>
          <TableCell>{i.cate.name}</TableCell>
          <TableCell>{i.y}年{i.m+1}月</TableCell>
          <TableCell>本期合计</TableCell>
          <TableCell>{Util.toMoney(i.de, true, '')}</TableCell>
          <TableCell>{Util.toMoney(i.cr, true, '')}</TableCell>
          <TableCell>{i.cate.de ? '借' : '贷'}</TableCell>
          <TableCell>{Util.toMoney(i.cate.de ? i.de - i.cr : i.cr - i.de, false, '')}</TableCell>
        </TableRow>)}
      </TableBody>
    </Table>}
    <DataCommon query={query} setQuery={setQuery} data={data} />
    {!data && <Loading variant="table" />}
  </Stack> : null;
}
