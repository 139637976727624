import {
  AccountCircle,
  Logout,
  ManageAccounts,
  Person,
} from "@mui/icons-material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Appinfo, financeStandardsName } from "../common/config";
import { Icontext } from "../interface";
import { colorMode, login } from "../services/app.service";
import Logo from "../ui/logo";
import { Me } from "./shard.interface";

export default function MyToolbar(props: { context: Icontext }) {
  const { context } = props;
  const theme = useTheme();
  const [me, setMe] = React.useState<Me | undefined>(login.me);
  const [usermenu, setUsermenu] = React.useState<any>();
  const navigate = useNavigate();
  const [, tab, path] = useLocation().pathname.split("/");

  React.useEffect(() => {
    login.changed.subscribe(setMe);
  }, []);

  function handleLogout() {
    axios.post("soft/logout", {}).then((r) => {
      login.me = undefined;
      login.changed.next(undefined);
      localStorage.removeItem("token");
    });
    setUsermenu(undefined);
  }

  function relogin() {
    login.loginForm.next(true);
  }

  return (
    <AppBar position="fixed" className="noprint">
      <Toolbar>
        <Logo title={Appinfo.name} />
        <Stack flexGrow={1} />
        <Button
          color="inherit"
          onClick={() => {
            navigate(path !== "orgSetting" ? "orgSetting" : "../1");
          }}>
          {context.orgs && !context.org && "未选择企业"}
          {context.org?.name}
          {context.finance?.name ? " / " + context.finance?.name : ""}
          {context.finance?.standards ? (
            <Typography variant="caption" sx={{ ml: 1 }}>{` [${
              financeStandardsName[context.finance.standards]
            }]`}</Typography>
          ) : (
            ""
          )}
        </Button>
        <Stack flexGrow={1} />
        {tab === "account" && <Typography variant="h6">用户中心</Typography>}
        <Stack flexGrow={1}></Stack>
        <Stack direction="row" spacing={2}>
          <IconButton
            sx={{ ml: 1 }}
            onClick={() => colorMode.sub.next(!colorMode.isDark)}
            color="inherit">
            {theme.palette.mode === "dark" ? (
              <LightModeIcon />
            ) : (
              <DarkModeIcon />
            )}
          </IconButton>
          <IconButton
            sx={{ p: 0 }}
            onClick={(e) => {
              if (!me) {
                login.loginForm.next(true);
              } else {
                setUsermenu(e.currentTarget);
              }
            }}>
            <Avatar
              alt={me?.nickname}
              src={me?.avatar || ""}
              sx={{ fontSize: ".8rem" }}>
              {!me ? (
                "登录"
              ) : me.avatar ? null : me.nickname ? (
                me.nickname.substring(me.nickname.length - 2)
              ) : (
                <Person />
              )}
            </Avatar>
          </IconButton>
        </Stack>
        <Menu
          anchorEl={usermenu}
          open={!!usermenu}
          onClick={() => setUsermenu(null)}
          onClose={() => setUsermenu(null)}>
          <Stack spacing={1} sx={{ minWidth: "14rem" }}>
            {me && (
              <Stack
                direction="row"
                spacing={2}
                sx={{ m: 2, alignItems: "center" }}>
                <Avatar
                  alt={me.nickname}
                  src={me.avatar || ""}
                  sx={{ fontSize: ".8rem" }}>
                  {me.avatar ? null : me.nickname ? (
                    me.nickname.substring(me.nickname.length - 2)
                  ) : (
                    <Person />
                  )}
                </Avatar>
                <Stack>{me.nickname || "~"}</Stack>
              </Stack>
            )}
            <Divider></Divider>
            <MenuItem
              color="primary"
              onClick={() => {
                navigate("account");
                setUsermenu(null);
              }}>
              <ListItemIcon>
                <ManageAccounts />
              </ListItemIcon>
              <ListItemText> 用户中心</ListItemText>
            </MenuItem>
            <Divider></Divider>
            <MenuItem onClick={relogin}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText> 重新登录</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText> 退出</ListItemText>
            </MenuItem>
          </Stack>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
