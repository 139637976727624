import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React from "react";
import { debounceTime, Subject } from "rxjs";
import { FormProps } from "./Form.interface";
/**
 *
 * @param props: {value, onSubmit, onChange}
 */

export default function FormKeyword(props: FormProps) {
  const sub = React.useRef(new Subject<any>());
  const [value, setValue] = React.useState(props.value);
  function handleChange(e: any) {
    setValue(e.target.value);
    sub.current.next(e);
  }
  React.useEffect(() => setValue(props.value), [props.value]);

  React.useEffect(() => {
    const _sub = sub.current.pipe(debounceTime(500)).subscribe(props.onChange);
    return () => _sub.unsubscribe();
    // eslint-disable-next-line
  }, []);

  return (
    <TextField
      label={props.label || "搜"}
      variant="filled"
      {...props.props}
      value={value}
      onKeyDown={(e) => (e.key === "Enter" ? props.onChange : undefined)}
      name={props.name || "keyword"}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value && (
              <IconButton
                onClick={() =>
                  handleChange({
                    target: { name: props.name || "keyword", value: "" },
                  })
                }>
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}></TextField>
  );
}
