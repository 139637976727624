import axios from "axios";
import { Ifinance, IfinanceCategory } from "../interface";

function buildFinanceCategory(financeCategory: IfinanceCategory[]) {
  financeCategory.forEach((i) => {
    const children = financeCategory.filter((ii) =>
      new RegExp(`^${i.code}\\d+`).test(ii.code)
    );
    i._childrenCodeLen = children.length
      ? Math.min(...children.map((ii) => ii.code.length)) || 0
      : 0;
    i._children = children.filter(
      (ii) => ii.code.length === i._childrenCodeLen
    );
    i._children.forEach((ii) => (ii._p = i));
    return i;
  });
  return financeCategory;
}

export function maxCodeLengthOfFinanceCategory(financeCategory: IfinanceCategory[]) {
  let _l = 0
  financeCategory.forEach(i => {
    _l = i.code.length>_l ? i.code.length : _l
  })
  return _l
}

export async function fetchFinance(_id: string, orgId: string) {
  const r = await axios.post("org/fetchFinance", { _id, orgId });
  const finance = r.data as Ifinance;
  if (!finance) return;
  finance.category.push(
    ...[
      { code: "1", name: "资产", py: "ZC", status: true, de: true },
      { code: "2", name: "负债", py: "FZ", status: true, de: true },
      { code: "3", name: "权益", py: "SYZQY", status: true, de: true },
      { code: "4", name: "成本", py: "CB", status: true, de: true },
      { code: "5", name: "损益", py: "SY", status: true, de: true },
    ]
  );
  finance.category = buildFinanceCategory(finance.category);
  return finance;
}

export function cateName(
  code: string,
  category: IfinanceCategory[],
  p = true
): string {
  const c = category.find((x) => x.code === code);
  if (p && c?._p?.code && c._p.code.length > 1) {
    const pname = cateName(c._p.code, category, p);
    return pname + "-" + (c?.name || code);
  } else {
    return c?.name || code;
  }
}
