import { Add, Close, Edit } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { voucherTypeOptions } from "../common/config";
import { mOpttions, yOptions } from "../finance/finance.funs";
import { Ivoucher } from "../finance/finance.interface";
import { IFormEvent } from "../form/Form.interface";
import FormKeyword from "../form/FormKeyword";
import FormSelect from "../form/FormSelect";
import { IcontextStatue } from "../interface";
import { cateName } from "../services/financeFuns";
import Util from "../services/util";
import DataCommon from "../ui/DataCommon";
import MoreMenu from "../ui/MoreMenu";
import VoucherForm from "./VoucherForm";
import { snk } from "../services/app.service";

export default function VoucherList() {
  const [y, m] = Util.ymd();
  const [context] = useOutletContext() as IcontextStatue;
  const { finance, orgId, financeId } = context;
  const [query, setQuery] = React.useState({
    keyword: "",
    voucherType: "",
    y,
    m,
    status: 0,
    skip: 0,
    limit: 10,
  });
  const [data, setData] = React.useState<{ data: Ivoucher[]; count: number }>();
  const [form, setForm] = React.useState<Ivoucher>();

  React.useEffect(find, [query, finance, orgId, financeId]);
  function find() {
    if (!finance || !orgId || !financeId) return;
    axios
      .post("finance/findVoucher", {
        orgId,
        financeId,
        where: {
          voucherType: query.voucherType || undefined,
          "at.y": query.y,
          "at.m": query.m,
          "items.title": query.keyword ? { $regex: query.keyword } : undefined,
          "attr.status": query.status,
        },
        fields: {},
        sort: {},
        limit: query.limit,
        skip: query.skip,
      })
      .then(({ data }) => {
        setData(data);
      });
  }

  function handleQueryChange(e: IFormEvent) {
    setQuery(Util.formChange(e, query));
  }

  if (!finance?._id) return null;
  return (
    <Stack sx={{ p: 2 }} spacing={2}>
      <Stack direction="row" alignItems={"center"} spacing={1}>
        <FormSelect
          label="类型"
          name="voucherType"
          value={query.voucherType}
          options={[{ value: "", title: "全部" }, ...voucherTypeOptions]}
          onChange={handleQueryChange}
        />
        <FormSelect
          label="年"
          name="y"
          value={query.y}
          options={yOptions(finance.beginAt.y!)}
          onChange={handleQueryChange}
        />
        <FormSelect
          label="月"
          name="m"
          value={query.m}
          options={mOpttions(query.y, finance.beginAt.date)}
          onChange={handleQueryChange}
        />
        <FormKeyword
          label="搜索"
          name="keyword"
          value={query.keyword}
          onChange={handleQueryChange}
        />
        <Stack flexGrow={1}>
          {query.status === 1 && <Button onClick={() => setQuery({ ...query, status: 0 })}>已作废<Close /></Button>}
        </Stack>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setForm({} as Ivoucher)}>
          录入
        </Button>
        <MoreMenu menuItem={[{
          title: query.status === 0 ? '查看已作废凭证' : '查看正常凭证', onClick: () => {
            setQuery({ ...query, status: query.status === 0 ? 1 : 0 })
          }
        }]} />
      </Stack>
      {!!data?.count &&
        data.data.map((i, index) => (
          <Card key={index}>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <Stack>日期: {format(i.at.date, "y-M-d")}</Stack>
                <Stack sx={{ color: "info.main" }}>
                  {i.voucherType}-{Util.preZero(i.voucherNo)}
                </Stack>
                {i.attr.status === 1 && <Stack sx={{ color: (theme) => theme.palette.error.main }}>
                  废
                </Stack>}
                <Stack flexGrow={1}></Stack>
                <Stack>合计: {Util.toMoneyCn(i.total._de)}</Stack>
              </Stack>
              <Table sx={{ "td,th": { pt: 0.2, pb: 0.2 } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>摘要</TableCell>
                    <TableCell>科目</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>借方</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>贷方</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {i.items.map((it, itIndex) => (
                    <TableRow key={itIndex}>
                      <TableCell>{it.title}</TableCell>
                      <TableCell>
                        {cateName(it.code, finance.category!)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {Util.toMoney(it._de, true, "")}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {Util.toMoney(it._cr, true, "")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Stack direction="row">
                <Stack>
                  制单:
                  {finance.members.find((x) => x._id === i.sign?.make)?.name}
                </Stack>
                <Stack flexGrow={1}></Stack>
                {query.status === 1 && <Button onClick={() => {
                  axios.post("finance/saveVoucher", {
                    orgId,
                    financeId,
                    _id: i._id,
                    data: { 'attr.status': 0 }
                  }).then(() => {
                    snk.next({ msg: '凭证已恢复' })
                    setQuery({ ...query, status: 0 })
                  });
                }}>
                  恢复
                </Button>}
                {query.status === 0 && <IconButton onClick={() => setForm({ ...i })}>
                  <Edit />
                </IconButton>}

                {query.status === 0 && <MoreMenu
                  menuItem={[
                    {
                      title: "作为模板添加",
                      onClick: () => setForm({ ...i, _id: undefined }),
                    },
                    {
                      title: '作废',
                      onClick: () => {
                        axios.post("finance/saveVoucher", {
                          orgId,
                          financeId,
                          _id: i._id,
                          data: { 'attr.status': 1 }
                        }).then(() => {
                          find()
                        });
                      }
                    }
                  ]}
                />}
              </Stack>
            </CardContent>
          </Card>
        ))}
      <DataCommon data={data} query={query} setQuery={setQuery} />
      {form && (
        <VoucherForm
          item={form}
          onClose={() => {
            setForm(undefined);
            find();
          }}
        />
      )}
    </Stack>
  );
}
