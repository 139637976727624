import { Stack } from "@mui/system";
import { Outlet, useOutletContext } from "react-router-dom";
import { IcontextStatue } from "../interface";

export default function Voucher() {
  const [context, setContext] = useOutletContext() as IcontextStatue;
  if (!context.finance) return null;
  return (
    <Stack>
      <Outlet context={[context, setContext]}></Outlet>
    </Stack>
  );
}
