import {
  Add,
  Close,
  DoDisturbAlt,
  DoneOutline,
  Edit,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { financeRoles, financeRolesName } from "../common/config";
import { IFormEvent } from "../form/Form.interface";
import FormSelect from "../form/FormSelect";
import FormSwitch from "../form/FormSwitch";
import FormText from "../form/FormText";
import { Ifinance, IinMembers } from "../interface";
import { snk } from "../services/app.service";
import Util from "../services/util";

export default function FinanceFormMember(props: {
  finance: Ifinance;
  onClose: () => void;
}) {
  const { onClose } = props;
  const [finance, setFinance] = React.useState<Ifinance>(props.finance);
  const [form, setForm] = React.useState<IinMembers>();
  async function handleSave() {
    const { _id, orgId } = finance;
    const r = await axios.post("org/saveFinance", {
      _id,
      orgId,
      data: { members: finance.members },
    });
    r.data && onClose();
  }

  async function handleChange(member?: IinMembers) {
    if (member) {
      if (member._id) {
        const mindex = finance.members.findIndex((i) => i._id === member._id);
        finance.members[mindex] = member;
        setFinance(finance);
        setForm(undefined);
      } else if (finance.members.find((i) => i.mobile === member.mobile)) {
        snk.next({ msg: "已经存在" });
      } else {
        const r = await axios.post("system/memberinfoForMb", {
          mobile: member.mobile,
        });
        const _id = r.data?._id;
        if (_id) {
          finance.members.push({ ...member, _id });
          setFinance(finance);
          setForm(undefined);
        } else {
          snk.next({ msg: "错误", severity: "error" });
        }
      }
    } else {
      setForm(undefined);
    }
  }

  return (
    <React.Fragment>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>
          <Stack direction="row" alignItems={"center"}>
            <Stack flexGrow={1}>用户</Stack>
            <Button
              onClick={() => {
                setForm({} as IinMembers);
              }}
              startIcon={<Add />}>
              新建
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Table sx={{ minWidth: 400 }}>
            <TableBody>
              {finance?.members?.map((i, index) => (
                <TableRow key={index}>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>{i.mobile}</TableCell>
                  <TableCell>
                    {i.roles.map((x) => financeRolesName[x]).join(", ")}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    {i.on ? (
                      <DoneOutline color="success" />
                    ) : (
                      <DoDisturbAlt color="disabled" />
                    )}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <IconButton
                      onClick={() => {
                        setForm({ ...i });
                      }}>
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        finance.members.splice(index, 1);
                        setFinance({ ...finance });
                        handleSave();
                      }}>
                      <Close />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!finance?.members.length && <Alert severity="info">没有</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>保存</Button>
        </DialogActions>
      </Dialog>
      {form && <FormMember form={form} onChange={handleChange} />}
    </React.Fragment>
  );
}

function FormMember(props: {
  form: IinMembers;
  onChange: (e?: IinMembers) => void;
}) {
  const { form: _form, onChange } = props;

  const [form, setForm] = React.useState<IinMembers>({
    ...{ name: "", mobile: "", on: true, roles: [] },
    ..._form,
  });

  function handleFormChange(e: IFormEvent) {
    const nf = Util.formChange(e, form);
    nf.roles = nf.roles.filter((i: string) =>
      financeRoles.find((ii) => i === ii.value)
    );
    setForm(nf);
  }

  return (
    <Dialog open={true} onClose={() => onChange()}>
      <DialogTitle>{form._id ? "修改" : "添加"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2, minWidth: 300 }}>
          <FormText
            label="姓名"
            name="name"
            value={form.name}
            onChange={handleFormChange}
          />
          {!form._id && (
            <FormText
              label="手机号"
              value={form.mobile}
              name="mobile"
              onChange={handleFormChange}
            />
          )}
          <FormSelect
            variant="outlined"
            multiple={true}
            label="角色"
            name="roles"
            value={form.roles}
            options={financeRoles}
            onChange={handleFormChange}
            sx={{ width: 200 }}
          />
          <Stack direction="row">
            {" "}
            <FormSwitch
              label="开启"
              name="on"
              value={form.on}
              onChange={handleFormChange}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onChange()}>取消</Button>
        <Button
          variant="contained"
          disabled={!/^\d{11}$/.test(form.mobile) || !form.name}
          onClick={() => onChange(form)}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
}
