import { Add, Edit } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { IFormEvent } from "../form/Form.interface";
import FormSwitch from "../form/FormSwitch";
import FormText from "../form/FormText";
import { IcontextStatue, Icurrency } from "../interface";
import { fetchFinance } from "../services/financeFuns";
import Util from "../services/util";

export default function FinanceSettingCurrency() {
  const [context, setContext] = useOutletContext() as IcontextStatue;
  const [currency, setCurrency] = React.useState(context.finance?.currency);
  const [form, setForm] = React.useState<Icurrency>();
  React.useEffect(() => {
    setCurrency(context.finance?.currency);
  }, [context]);

  function handleFormChange(e: IFormEvent) {
    setForm(Util.formChange(e, form));
  }

  async function handleSave() {
    if (!currency || !form) return;
    const _currency = [...currency];
    const i = _currency.findIndex((i) => i._id === form!._id);
    if (i > -1) {
      _currency[i] = { ...form };
    } else {
      _currency.push({ ...form });
    }
    save(_currency);
  }

  async function handleRemove() {
    if (!currency || !form) return;
    const _currency = currency.filter((i) => i._id !== form._id);
    save(_currency);
  }

  async function save(_currency: Icurrency[]) {
    const r = await axios.post("finance/saveFinance", {
      orgId: context.orgId,
      financeId: context.finance!._id,
      currency: _currency,
    });
    if (r.data) {
      setForm(undefined);
      fetchFinance(context.finance!._id!, context.orgId!).then((r) => {
        setContext({ ...context, finance: r });
      });
    }
  }

  if (!context.finance || !currency) return null;

  return (
    <Stack sx={{ p: 2 }}>
      <Stack direction="row-reverse">
        <Button
          startIcon={<Add />}
          onClick={() =>
            setForm({
              _id: "",
              name: "",
              symbol: "",
              rate: 1,
              isLocal: false,
            })
          }>
          添加
        </Button>
        <Stack flexGrow={1}>
          {currency.filter((i) => i.isLocal).length !== 1 && (
            <Alert severity="error">只能有一种本币</Alert>
          )}
        </Stack>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>货币</TableCell>
            <TableCell>名称</TableCell>
            <TableCell>符号</TableCell>
            <TableCell>汇率</TableCell>
            <TableCell>是否本币</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{_row(currency)}</TableBody>
      </Table>
      {_form()}
    </Stack>
  );

  function _row(items?: Icurrency[]) {
    return items?.map((i, index) => (
      <React.Fragment key={index}>
        <TableRow hover>
          <TableCell>{i._id}</TableCell>
          <TableCell>{i.name}</TableCell>
          <TableCell>{i.symbol}</TableCell>
          <TableCell>{Util.toMoney(i.rate * 100, false, "")}</TableCell>
          <TableCell>{i.isLocal ? "本币" : "外币"}</TableCell>
          <TableCell sx={{ p: 0 }}>
            <IconButton onClick={() => setForm({ ...i })}>
              <Edit />
            </IconButton>
          </TableCell>
        </TableRow>
      </React.Fragment>
    ));
  }

  function _form() {
    if (!form) return;
    return (
      <Dialog open={true} onClose={() => setForm(undefined)}>
        <DialogTitle>{form._id}</DialogTitle>
        <DialogContent>
          <Stack sx={{ mt: 2 }} spacing={2}>
            <FormText
              label="ID"
              name="_id"
              value={form._id}
              onChange={handleFormChange}
            />
            <FormText
              label="名称"
              name="name"
              value={form.name}
              onChange={handleFormChange}
            />
            <FormText
              label="符号"
              name="symbol"
              value={form.symbol}
              onChange={handleFormChange}
            />
            <FormText
              label="汇率"
              name="rate"
              value={form.rate}
              onChange={handleFormChange}
            />
            <FormSwitch
              label="是本币"
              name="isLocal"
              value={form.isLocal}
              onChange={handleFormChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setForm(undefined)}>取消</Button>
          <Button onClick={handleRemove}>删除</Button>
          <Button variant="contained" onClick={handleSave}>
            保存
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
