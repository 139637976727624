import TextField from "@mui/material/TextField";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { zhCN } from "date-fns/locale";
import * as React from "react";
import { FormProps } from "./Form.interface";

export default function FormDate(props: FormProps) {
  const {
    label,
    name,
    onChange,
    inputFormat,
    mask,
    time,
    minDate,
    maxDate,
    sx,
    views,
    variant,
  } = props;
  const [value, setValue] = React.useState(new Date(props.value) || new Date());

  const handleChange = (newValue: any) => {
    setValue(newValue);
    if (!newValue.valueOf()) return;
    onChange({ target: { name, value: new Date(newValue).valueOf() } });
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhCN}>
      <DateTimePicker
        minDate={minDate}
        maxDate={maxDate}
        renderInput={(props) => (
          <TextField variant={variant} sx={sx} {...props} />
        )}
        inputFormat={inputFormat || time ? "yyyy/MM/dd HH:mm" : "yyyy/MM/dd"}
        label={label}
        value={value}
        mask={mask || time ? "____/__/__ __:__" : "____/__/__"}
        views={
          views || [
            "year",
            "month",
            "day",
            ...(time ? ["hours", "minutes"] : []),
          ]
        }
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
}
