import { Stack } from "@mui/material";

export default function VoucherWidgetInputTitle() {
  return (
    <Stack direction="row">
      {"亿千百十万千百十元角分".split("").map((i, index) => (
        <Stack key={index} flexGrow={1} sx={VoucherWidgetInputSx(index)}>
          {i}
        </Stack>
      ))}
    </Stack>
  );
}

export function VoucherWidgetInputSx(index: number) {
  return {
    borderRight:
      index === 8
        ? "1px solid #ff9800"
        : index === 5 || index === 2
        ? "1px solid #03a9f4"
        : index === 10
        ? "1px solid #9e9e9e"
        : "1px solid #9e9e9e30",
    borderLeft: index === 0 ? "1px solid #9e9e9e30" : "",
  };
}
