import { Observable } from "rxjs";

export default class ImgsetService {
  resize(base: string, maxw = 800, maxh = 800): Observable<string> {
    return new Observable((res) => {
      const img = new Image();
      img.src = base;
      img.onload = () => {
        const wh = this.WH(img.width, img.height, maxw, maxh);
        const canvas = document.createElement("canvas");
        canvas.width = wh.w;
        canvas.height = wh.h;
        canvas.getContext("2d")?.drawImage(img, 0, 0, wh.w, wh.h);
        res.next(canvas.toDataURL());
        res.complete();
      };
    });
  }

  WH(w: number, h: number, maxw: number, maxh: number) {
    let ww;
    let hh;
    if (w > maxw || h > maxh) {
      ww = maxw;
      hh = (maxw / w) * h;
      if (hh > maxh) {
        ww = (maxh / hh) * ww;
        hh = maxh;
      }
    } else {
      ww = w;
      hh = h;
    }
    return {
      w: Math.round(ww),
      h: Math.round(hh),
    };
  }

  readBig(file: File): Observable<[string, number, number]> {
    // const chunkSize = 1024 * 1024 * 50;
    const chunkSize = 1024 * 1024;
    const chunkCount = Math.ceil(file.size / chunkSize);
    const status = Array(chunkCount);
    return new Observable((ser) => {
      for (let i = 0; i < chunkCount; i++) {
        const reader = new FileReader();
        reader.onload = () => {
          status[i] = true;
          ser.next([reader.result as string, status.length, i]);
          if (status.filter((i) => i).length === chunkCount) {
            ser.complete();
          }
        };
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        reader.readAsDataURL(file.slice(start, end));
      }
    });
  }

  readimg(file: Blob, maxw = 800, maxh = 800): Observable<string> {
    return new Observable((ser) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        if (file.type.split("/")[0] === "image") {
          this.resize(base64, maxw, maxh).subscribe((r) => {
            ser.next(r);
            ser.complete();
          });
        } else {
          ser.next(base64);
          ser.complete();
        }
      };
      reader.readAsDataURL(file);
    });
  }

  dataURLToBlob(dataURL: string) {
    const BASE64_MARKER = ";base64,";
    let parts;
    let contentType;
    let raw;
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      parts = dataURL.split(",");
      contentType = parts[0].split(":")[1];
      raw = decodeURIComponent(parts[1]);
      return new Blob([raw], { type: contentType });
    }
    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(":")[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }
}
