import {
  Add,
  Close,
  DoDisturbAlt,
  DoneOutline,
  Edit,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { addYears, format } from "date-fns";
// import moment from "moment";
import React from "react";
import { orgRoles, orgRolesName } from "../common/config";
import { IFormEvent } from "../form/Form.interface";
import FormDate from "../form/FormDate";
import FormKeyword from "../form/FormKeyword";
import FormSelect from "../form/FormSelect";
import FormSwitch from "../form/FormSwitch";
import FormText from "../form/FormText";
import { IinMembers, Iorg } from "../interface";
import { snk } from "../services/app.service";
import Util from "../services/util";
import DataCommon from "../ui/DataCommon";

export default function Org() {
  const [query, setQuery] = React.useState<{
    keyword: string;
    skip: number;
    limit: number;
  }>({ keyword: "", skip: 0, limit: 10 });
  const [data, setData] = React.useState<{ data: Iorg[]; count: number }>();
  const [form, setForm] = React.useState<Iorg>();
  const [formMember, setFormMember] = React.useState<IinMembers>();

  async function find() {
    const { limit, skip } = query;
    const {
      data: { data, count },
    }: any = await axios.post("find/org", {
      where: {},
      limit,
      skip,
      sort: { expired: 1 },
    });
    setData({ data, count });
  }
  React.useEffect(() => {
    find();
    // eslint-disable-next-line
  }, [query]);

  function handleQueryChange(e: IFormEvent) {
    setQuery(Util.formChange(e, query));
  }
  function handleFormChange(e: IFormEvent) {
    setForm(Util.formChange(e, form));
  }
  function handleFormMemberChange(e: IFormEvent) {
    const nf = Util.formChange(e, formMember);
    nf.mobile = (nf.mobile.match(/\d+/g) || []).join("");
    setFormMember(nf);
  }

  async function handleSave() {
    const r: any = await (form?._id
      ? axios.post("save/org", { data: form, where: { _id: form._id } })
      : axios.post("add/org", { data: form }));
    if (r?.data) {
      setForm(undefined);
      find();
    }
  }

  const newForm = () =>
    setForm({
      members: [] as any[],
      maxFinance: 100,
      expired: addYears(new Date(), 1).valueOf(),
    } as Iorg);

  async function handleAddMember() {
    if (!formMember || !form) return;
    if (formMember._id) {
      const index = form.members.findIndex((i) => i._id === formMember._id);
      form.members[index] = formMember;
      setForm(form);
      setFormMember(undefined);
    } else if (
      form!.members.findIndex((i) => i.mobile === formMember.mobile) > -1
    ) {
      snk.next({ msg: "已存在", severity: "error" });
    } else {
      // console.log("_id");
      const {
        data: { _id },
      } = await axios.post("system/memberinfoForMb", {
        mobile: formMember.mobile,
      });
      if (_id) {
        form!.members.push({ ...formMember, _id });
        setForm({ ...form! });
        setFormMember(undefined);
      } else {
        snk.next({ msg: "错误", severity: "error" });
      }
    }
  }

  return (
    <Container>
      <Stack direction="row" sx={{ mt: 2 }} alignItems="center">
        <FormKeyword
          name={"keyword"}
          value={query.keyword}
          label={"搜"}
          onChange={handleQueryChange}
        />
        <Stack flexGrow={1}></Stack>
        <Button variant="contained" startIcon={<Add />} onClick={newForm}>
          添加
        </Button>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>名称</TableCell>
            <TableCell>管理员</TableCell>
            <TableCell>账套数</TableCell>
            <TableCell>到期时间</TableCell>
            <TableCell>创建时间</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.data?.map((i, index) => {
            const admin = i.members.filter(
              (i: any) => i.on && i.roles.includes("admin")
            );
            return (
              <TableRow
                key={index}
                hover
                onClick={() => setForm({ ...i, members: [...i.members] })}>
                <TableCell>{i.name}</TableCell>
                <TableCell sx={{ p: 0 }}>
                  <Stack direction="row" spacing={1}>
                    {admin.map((a, aindex) => (
                      <Chip key={aindex} label={a.name || a.mobile} />
                    ))}
                    {admin.length > 2 && <Stack>..{admin.length}</Stack>}
                  </Stack>
                </TableCell>
                <TableCell>
                  {i.nowFinance}/{i.maxFinance}
                </TableCell>
                <TableCell>{format(new Date(i.expired), "y-M-d")}</TableCell>
                <TableCell>
                  {format(new Date(i.createdAt!), "y-M-d hh:m:s")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <DataCommon data={data} query={query} setQuery={setQuery} />

      {form && Form(setForm, form, handleFormChange, setFormMember, handleSave)}
      {formMember && (
        <FormMember
          setFormMember={setFormMember}
          formMember={formMember}
          handleFormMemberChange={handleFormMemberChange}
          handleAddMember={handleAddMember}
        />
      )}
    </Container>
  );
}

// 企业表单
function Form(
  setForm: React.Dispatch<React.SetStateAction<Iorg | undefined>>,
  form: Iorg,
  handleFormChange: (e: IFormEvent) => void,
  setFormMember: React.Dispatch<React.SetStateAction<IinMembers | undefined>>,
  handleSave: () => Promise<void>
): React.ReactNode {
  return (
    <Dialog
      open={true}
      onClose={() => {
        setForm(undefined);
      }}>
      <DialogTitle>{form._id ? "修改企业" : "新建企业"}</DialogTitle>
      <DialogContent>
        <Stack sx={{ mt: 2 }} spacing={2}>
          <FormText
            label="名称"
            name="name"
            value={form.name}
            onChange={handleFormChange}
          />
          <FormDate
            label="到期日期"
            minDate={Date.now()}
            name="expired"
            value={form.expired}
            onChange={handleFormChange}
          />
          <FormText
            type={"number"}
            label="最大账套数"
            name="maxFinance"
            value={form.maxFinance}
            onChange={handleFormChange}
          />
          <Stack direction="row" alignItems={"center"}>
            <Typography flexGrow={1} variant="caption">
              管理员
            </Typography>
            <IconButton
              onClick={() =>
                setFormMember({
                  name: "",
                  mobile: "",
                  roles: ["admin"],
                  on: true,
                })
              }>
              <Add />
            </IconButton>
          </Stack>
          <Table>
            <TableBody>
              {form.members.map((i, index) => (
                <TableRow key={index}>
                  <TableCell>{i.name}</TableCell>
                  <TableCell>{i.mobile}</TableCell>
                  <TableCell>
                    {i.roles.map((x) => orgRolesName[x]?.title).join(", ")}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    {i.on ? (
                      <DoneOutline color="success" />
                    ) : (
                      <DoDisturbAlt color="disabled" />
                    )}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <IconButton
                      onClick={() => {
                        setFormMember({ ...i });
                      }}>
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        form.members.splice(index, 1);
                        setForm({ ...form });
                      }}>
                      <Close />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={!form.name} onClick={handleSave}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
}

//  企业用户表单
function FormMember(props: {
  setFormMember: React.Dispatch<React.SetStateAction<IinMembers | undefined>>;
  formMember: IinMembers;
  handleFormMemberChange: (e: IFormEvent) => void;
  handleAddMember: () => Promise<void>;
}) {
  const { setFormMember, formMember, handleFormMemberChange, handleAddMember } =
    props;
  return (
    <Dialog open={true} onClose={() => setFormMember(undefined)}>
      <DialogTitle>
        {formMember._id ? `修改(${formMember.mobile})` : "添加"}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2, minWidth: 300 }}>
          <FormText
            label="姓名"
            name="name"
            value={formMember.name}
            onChange={handleFormMemberChange}
          />
          {!formMember._id && (
            <FormText
              label="手机号"
              value={formMember.mobile}
              name="mobile"
              onChange={handleFormMemberChange}
            />
          )}
          <FormSelect
            sx={{ width: 200 }}
            variant="outlined"
            multiple={true}
            label="角色"
            name="roles"
            value={formMember.roles}
            options={orgRoles}
            onChange={handleFormMemberChange}
          />
          <Stack direction={"row"}>
            <FormSwitch
              label="开启"
              name="on"
              value={formMember.on}
              onChange={handleFormMemberChange}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setFormMember(undefined)}>取消</Button>
        {!/^\d{11}$/.test(formMember.mobile) && "x"}
        <Button
          variant="contained"
          disabled={!/^\d{11}$/.test(formMember.mobile) || !formMember.name}
          onClick={handleAddMember}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
}
