import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { To, useNavigate } from "react-router-dom";

export default function NotFound(props: { home?: To; back?: boolean }) {
  let navigate = useNavigate();
  const handleReload = () => {
    window.location.reload();
  };
  const handleback = () => {
    window.history.back();
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80%",
      }}>
      <Alert
        onClick={(e) => e.stopPropagation()}
        severity="info"
        sx={{ bgcolor: "transparent", mt: 20 }}>
        <AlertTitle>未找到模块</AlertTitle>
        <Box>
          <Box component="span">
            请刷新重试或
            {props.home && (
              <Button onClick={() => navigate(props.home || "/")}>
                到首页
              </Button>
            )}
            {props.back && <Button onClick={handleback}>后退</Button>}
          </Box>
          <Box sx={{ ml: -1, mt: 2 }}>
            <Button
              sx={{ ml: "12rem" }}
              variant="outlined"
              onClick={handleReload}
              startIcon={<RefreshIcon />}>
              重新载入
            </Button>
          </Box>
        </Box>
      </Alert>
    </Box>
  );
}
