import { useNavigate } from "react-router-dom";
import { Ivoucher } from "../finance/finance.interface";
import VoucherForm from "./VoucherForm";

export default function VoucherAdd() {
  const navigate = useNavigate();

  return (
    <VoucherForm
      item={{} as Ivoucher}
      onClose={() => {
        navigate("../");
      }}
    />
  );
}
