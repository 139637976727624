import { ButtonGroup, Button } from "@mui/material";

export default function VoucherWidgetLine(props: { setFlag: any; flag: any }) {
  const { setFlag, flag } = props;
  return (
    <ButtonGroup color="info" size="small">
      <Button
        disabled={flag.rows <= 2}
        onClick={() => setFlag({ ...flag, rows: flag.rows - 2 })}>
        -
      </Button>
      <Button>{flag.rows}行</Button>
      <Button onClick={() => setFlag({ ...flag, rows: flag.rows + 2 })}>
        +
      </Button>
    </ButtonGroup>
  );
}
