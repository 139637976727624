import axios from "axios";
import { Subject } from "rxjs";
import { Iorg } from "../interface";
import { login } from "./app.service";

login.changed.subscribe((r) => {
  my.getorg();
});
// 我的企业
export const my: { orgs?: Iorg[]; getorg: () => void; orgsSub: Subject<any> } =
  {
    orgsSub: new Subject(),
    getorg: () => {
      if (login.me?._id) {
        axios
          .post("org/myorg")
          .then(({ data }) => {
            my.orgs = data || [];
            my.orgsSub.next(my.orgs);
          })
          .catch(() => (my.orgs = []));
      } else {
        my.orgs = [];
        my.orgsSub.next(my.orgs);
      }
    },
  };
