import { Edit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { read, utils } from "xlsx";
import { IFormEvent } from "../form/Form.interface";
import FormText from "../form/FormText";
import { IcontextStatue } from "../interface";
import { snk } from "../services/app.service";
import Util from "../services/util";
import Excel from "../ui/icon/Excel";
import Import from "../ui/icon/Import";
import { toItems, toLocalCurrency } from "./finance.funs";
import { IinitialData, IinitialForm, IvoucherItem } from "./finance.interface";
//=============================================================={ 期初余额 }
export default function FinanceInitialBalance() {
  const [context] = useOutletContext() as IcontextStatue;
  const { finance } = context;
  const { category, currency } = finance || {};
  const [query, setQuery] = React.useState({ tab: "0" });
  const [data, setData] = React.useState<IinitialData[]>();
  const [dataFiltered, setDataFiltered] = React.useState<IinitialData[]>();
  const [count, setCount] = React.useState<number>();
  const [form, setForm] = React.useState<IinitialForm>();

  // eslint-disable-next-line
  React.useEffect(find, []);
  React.useEffect(() => {
    const f = data?.filter(
      (i) => query.tab === "0" || new RegExp(`^${query.tab}`).test(i.cate.code)
    );
    setDataFiltered(f);
    const d = f ? Util.sum(f.map((i) => i._de || 0)) : 0;
    const c = f ? Util.sum(f.map((i) => i._cr || 0)) : 0;
    setCount((d / (c + d)) * 100);
  }, [data, query.tab]);

  if (!finance) return null;
  return (
    <Stack sx={{ p: 2 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="caption">
          账套期初{format(finance.beginAt.date, "y年M月d日")}
        </Typography>
        <Button
          startIcon={<Import />}
          onClick={async () => {
            const r = await Util.readXlsxFile(["xlsx"]);
            if (!r) return;
            try {
              const workBook = read(r);
              const sheet: any[] = utils.sheet_to_json(
                workBook.Sheets[workBook.SheetNames[0]]
              );
              if (
                Util.intersection(
                  ["编码", "借方", "贷方"],
                  Object.keys(sheet[0])
                ).length !== 3
              ) {
                snk.next({
                  msg: "必须包含列: 编码,借方,贷方",
                  severity: "error",
                });
                return;
              }
              const d: IinitialData[] = sheet.map((i) => {
                const { 编码, 借方, 贷方 } = i;
                const cate = category?.find((x) => x.code === 编码)!;
                return {
                  cate,
                  _de: (Number(借方) || 0) * 100,
                  _cr: (Number(贷方) || 0) * 100,
                  _t: (cate.de ? 借方 - 贷方 : 贷方 - 借方) * 100,
                  _c: cate.currency!,
                };
              });
              await save(toItems(d, currency!));
              find();
            } catch (e: any) {
              snk.next({ msg: `${e}`, severity: "error" });
            }
          }}>
          导入
        </Button>
        <Button
          startIcon={<Excel />}
          onClick={() => {
            const jsonData = data!
              .filter((i) => !i.cate._children?.length)
              .map((i) => {
                const {
                  cate: { code, name, de },
                  _c,
                  _cr,
                  _de,
                  _t,
                } = i;
                return {
                  编码: code,
                  名称: name,
                  币种: _c,
                  方向: de ? "借" : "贷",
                  余额: _t ? _t / 100 : "",
                  借方: _de ? _de / 100 : "",
                  贷方: _cr ? _cr / 100 : "",
                };
              });
            Util.toXlsxFile(
              jsonData,
              `期初余额-${finance.name}-${format(new Date(), "yyyyMMdd")}.xlsx`,
              "期初余额"
            );
          }}>
          导出
        </Button>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <Tabs
          value={query.tab}
          onChange={(e, tab) => setQuery({ ...query, tab })}>
          <Tab value="0" label="全部" />
          <Tab value="1" label="资产" />
          <Tab value="2" label="负债" />
          <Tab value="3" label="权益" />
          <Tab value="4" label="成本" />
          <Tab value="5" label="损益" />
        </Tabs>
        <Stack flexGrow={1}></Stack>
        {dataFiltered && !!count && Math.abs(count) !== Infinity && (
          <Stack>
            <LinearProgress
              color={count === 50 ? "success" : "error"}
              sx={{ width: 100 }}
              variant="determinate"
              value={count}
            />
            <Stack direction="row">
              <Typography>借</Typography>
              <Typography textAlign="center" flexGrow={1}>
                {count === 50 ? "=" : (count || 0) < 50 ? "<" : ">"}
              </Typography>
              <Typography>贷</Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Divider />
      {category && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>编码</TableCell>
              <TableCell>名称</TableCell>
              <TableCell>币种</TableCell>
              <TableCell>方向</TableCell>
              <TableCell>余额</TableCell>
              <TableCell>借方</TableCell>
              <TableCell>贷方</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataFiltered?.map((i, index) => {
              const { cate, _c, _t, _de, _cr } = i;
              return (
                <TableRow key={index}>
                  <TableCell sx={{ pl: cate.code.length - 2 }}>
                    {cate.code}
                  </TableCell>
                  <TableCell
                    sx={
                      cate._children?.length
                        ? {
                            fontStyle: "italic",
                            color: "info.main",
                          }
                        : {}
                    }>
                    {cate.name}
                  </TableCell>
                  <TableCell>{_c}</TableCell>
                  <TableCell>{cate.de ? "借" : "贷"}</TableCell>
                  <TableCell>{Util.toMoney(_t, true, "")}</TableCell>
                  <TableCell>{Util.toMoney(_de, true, "")}</TableCell>
                  <TableCell>{Util.toMoney(_cr, true, "")}</TableCell>
                  <TableCell sx={{ p: 0 }}>
                    {(cate._children?.length || 0) === 0 && (
                      <IconButton
                        onClick={() => {
                          const { code, name } = cate;
                          setForm({ code, name, _de, _cr });
                        }}>
                        <Edit />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {form && (
        <FinanceInitialBalanceForm
          form={form}
          handleSave={handleSave}
          onClose={() => setForm(undefined)}
        />
      )}
    </Stack>
  );

  // 读取
  function find() {
    (async (orgId, financeId) => {
      const {
        data: { data },
      } = await axios.post("finance/findVoucher", {
        orgId,
        financeId,
        skip: 0,
        limit: 1,
        where: { "attr.initial": true },
      });
      setData(budData(data[0]?.items || []));
    })(context.orgId, context.financeId);
  }

  // 表单保存
  async function handleSave(_form: any) {
    const items = toItems(data!, currency!);
    const isEx = items.findIndex((i) => i.code === _form?.code);
    const { code, name: title, _de, _cr } = _form!;
    const _c = category!.find((x) => x.code === code)?.currency;
    const item = {
      title,
      code,
      _de,
      _cr,
      de: toLocalCurrency(currency!, _de, _c),
      cr: toLocalCurrency(currency!, _cr, _c),
      currency: _c || "",
      supplementary: { id: [] },
    };
    if (isEx > -1) {
      items[isEx] = item;
    } else {
      items.push(item);
    }
    await save(items);
    find();
    setForm(undefined);
  }

  // 组装数据
  function budData(items: IvoucherItem[]) {
    return (
      category
        ?.filter((cate) => !(cate.code.length < 2))
        .map((cate) => {
          const v = cate._children?.length
            ? {
                _de: Util.sum(
                  cate._children.map(
                    (c) => items.find((i) => i.code === c.code)?._de || 0
                  )
                ),
                _cr: Util.sum(
                  cate._children.map(
                    (c) => items.find((i) => i.code === c.code)?._cr || 0
                  )
                ),
              }
            : items.find((i) => i.code === cate.code) || {
                _de: 0,
                _cr: 0,
              };

          const _c =
            cate.currency || currency?.find((c) => c.isLocal)?._id || "RMB";
          const { _cr, _de } = v;
          const _t = cate.de ? _de - _cr : _cr - _de;
          return { cate, _de, _cr, _t, _c };
        }) || []
    );
  }

  // 保存
  async function save(items: IvoucherItem[]) {
    const voucher = {
      orgId: context.orgId!,
      financeId: context.financeId!,
      items,
      total: {
        _de: Util.sum(items.map((i) => i._de)),
        _cr: Util.sum(items.map((i) => i._cr)),
        de: Util.sum(items.map((i) => i.de)),
        cr: Util.sum(items.map((i) => i.cr)),
      },
      at: { y: 0, m: 0, d: 0, date: new Date(0).valueOf() },
      "attr.initial": true,
    };
    await axios.post("finance/saveVoucher", {
      orgId: voucher.orgId,
      financeId: voucher.financeId,
      data: voucher,
    });
  }
}

//=============================================================={ 修改表单 }

function FinanceInitialBalanceForm({
  form: { code, name, _de, _cr },
  handleSave,
  onClose,
}: {
  form: { code: string; name: string; _de: number; _cr: number };
  handleSave: (form: any) => void;
  onClose: () => void;
}) {
  const [form, setForm] = React.useState({
    code,
    name,
    _cr: _cr ? _cr / 100 : "",
    _de: _de ? _de / 100 : "",
  });
  function handleFormChange(e: IFormEvent) {
    setForm(Util.formChange(e, form));
  }
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        {form.code}:{form.name}
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ mt: 2 }} spacing={2}>
          <FormText
            type="number"
            label="借方"
            name="_de"
            value={form._de}
            onChange={handleFormChange}
          />
          <FormText
            type="number"
            label="贷方"
            name="_cr"
            value={form._cr}
            onChange={handleFormChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button
          variant="contained"
          onClick={() =>
            handleSave({
              ...form,
              _de: Number(form._de) * 100 || 0,
              _cr: Number(form._cr) * 100 || 0,
            })
          }>
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
}
