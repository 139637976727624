import { Checkbox, ListItemText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { FormProps, FormSelectOption } from "./Form.interface";

export default function FormSelect(props: FormProps) {
  const sx = { minWidth: 120, ...props.sx };
  const {
    label,
    disabled = false,
    onChange,
    options = [],
    name,
    value,
    variant = "filled",
    multiple = false,
  } = props;
  return (
    <FormControl variant={variant} disabled={disabled}>
      <InputLabel filled={true}>{label || name}</InputLabel>
      <Select
        sx={sx}
        value={value}
        label={label}
        name={name}
        multiple={multiple}
        onChange={onChange}
        renderValue={
          multiple
            ? (selected) =>
                selected
                  .map((v: any, i: any) => options[i] && options[i].title)
                  .join(", ")
            : (selected) => options.find((i) => i.value === selected)?.title
        }>
        {options.map((item: FormSelectOption, index: number) => (
          <MenuItem key={index} value={item.value}>
            {multiple && <Checkbox checked={value?.indexOf(item.value) > -1} />}
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
