import { Add, Edit, GroupAdd } from "@mui/icons-material";
import {
  Alert,
  Button,
  Chip,
  Container,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import FinanceForm from "../finance/FinanceForm";
import FinanceFormMember from "../finance/FinanceFormMember";
import { IFormEvent } from "../form/Form.interface";
import FormKeyword from "../form/FormKeyword";
import FormSelect from "../form/FormSelect";
import { IcontextStatue, Ifinance } from "../interface";
import { orgAndMyinfo } from "../services/app.service";
import { fetchFinance } from "../services/financeFuns";
import Util from "../services/util";
import Loading from "../ui/loading";
import { financeStandardsName } from "./config";

export default function OrgSetting() {
  const [context, setContext] = useOutletContext() as IcontextStatue;
  const [query, setQuery] = useState({ keyword: "", skip: 0, limit: 20 });
  const [data, setData] = React.useState<{ data: Ifinance[]; count: number }>();
  const [form, setForm] = React.useState<Ifinance>();
  const [formMember, setFormMember] = React.useState<Ifinance>();

  const navigate = useNavigate();

  function handleOrgChange(e: IFormEvent) {
    const { value: orgId } = e.target;
    localStorage.setItem("orgId", orgId);
    const { org, myinfo } = orgAndMyinfo({ ...context, orgId });
    setContext({ ...context, orgId, org, myinfo });
  }

  function handleQueryChange(e: IFormEvent) {
    setQuery(Util.formChange(e, query));
  }

  async function find() {
    if (context.myinfo) {
      const { data } = await axios.post("org/findFinances", {
        ...query,
        orgId: context.orgId,
      });
      setData(data);
    } else {
      setData(undefined);
    }
  }

  useEffect(() => {
    context.orgId && find();
    // eslint-disable-next-line
  }, [context.orgId, query]);

  async function handleSelect(i: Ifinance) {
    const u = i.members.find((x) => x.on && x._id === context.me?._id);
    if (u) {
      const finance = await fetchFinance(i._id!, i.orgId);
      setContext({ ...context, financeId: i._id, finance });
      localStorage.setItem("financeId", i._id || "");
      navigate("../");
    }
  }

  return !!context.orgs?.length ? (
    <Container>
      <Stack spacing={2}>
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
          账套
        </Typography>
        <Stack direction="row" spacing={2}>
          <FormSelect
            label="企业"
            name=""
            value={context.orgId}
            options={
              context.orgs.map((i) => ({ title: i.name, value: i._id })) || []
            }
            onChange={handleOrgChange}
          />
          <Stack flexGrow={1}>
            <FormKeyword
              label="关键字"
              name={"keyword"}
              value={query.keyword}
              onChange={handleQueryChange}
            />
          </Stack>

          {context.myinfo?.roles.includes("admin") && (
            <Button
              size="small"
              startIcon={<Add />}
              onClick={() => {
                setForm({ orgId: context.orgId } as Ifinance);
              }}>
              创建账套
            </Button>
          )}
        </Stack>
        {data && data.count > 0 ? (
          <React.Fragment>
            <Table>
              <TableBody>
                {data?.data.map((i, index) => {
                  return (
                    <TableRow key={index} hover onClick={() => handleSelect(i)}>
                      <TableCell>{i.name}</TableCell>
                      <TableCell>
                        <i>{financeStandardsName[i.standards]}</i>
                      </TableCell>
                      <TableCell>{format(i.beginAt.date, "y-M-d")}</TableCell>
                      <TableCell>
                        {i.members.length === 0 &&
                          context.myinfo?.roles.includes("admin") &&
                          "无用户"}
                        {i.members.map((a, ai) => (
                          <Chip key={ai} label={a.name || a.mobile} />
                        ))}
                      </TableCell>
                      <TableCell sx={{ p: 0 }}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setFormMember(i);
                          }}>
                          <GroupAdd />
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ p: 0 }}>
                        {context.myinfo?.roles.includes("admin") && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setForm({ ...i });
                            }}>
                            <Edit />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {data && (
              <Pagination
                page={query.skip / query.limit + 1}
                count={Math.ceil(data.count / query.limit)}
                onChange={(e, page) =>
                  setQuery({ ...query, skip: (page - 1) * query.limit })
                }
              />
            )}
          </React.Fragment>
        ) : (
          <Stack alignItems={"center"}>
            {data && (
              <Alert
                severity="info"
                action={
                  context.myinfo?.roles.includes("admin") ? (
                    <Button
                      size="small"
                      onClick={() => {
                        setForm({ orgId: context.orgId } as Ifinance);
                      }}>
                      创建
                    </Button>
                  ) : null
                }>
                {context.myinfo?.roles.includes("admin")
                  ? "尚未创建账套"
                  : "没有我有权限的账套, 请联系管理员"}
              </Alert>
            )}
          </Stack>
        )}

        {form && (
          <FinanceForm
            finance={form}
            onClose={() => {
              setForm(undefined);
              find();
            }}
          />
        )}
      </Stack>
      {formMember && (
        <FinanceFormMember
          finance={formMember}
          onClose={() => setFormMember(undefined)}
        />
      )}
    </Container>
  ) : (
    <Stack alignItems={"center"}>
      {context.orgs && context.orgs.length === 0 && (
        <Alert severity="info">没有企业</Alert>
      )}
      {!context.orgs && <Loading variant="table" />}
    </Stack>
  );
}
