import { Alert, Button, Pagination } from "@mui/material";
import Loading from "./loading";

export default function DataCommon(props: {
  data?: any;
  nodataLabel?: string;
  reloadFun?: any;
  error?: string;
  query: any;
  setQuery: any;
}) {
  const { data, nodataLabel, reloadFun, error, query, setQuery } = props;
  const res = {
    loading: () => <Loading variant="table" />,
    error: () => (
      <Alert
        sx={{ m: 2 }}
        color="error"
        action={
          reloadFun && (
            <Button color="inherit" size="small" onClick={reloadFun}>
              重试
            </Button>
          )
        }>
        {error}
      </Alert>
    ),
    nodata: () => (
      <Alert
        sx={{ m: 2 }}
        color="info"
        action={
          reloadFun && (
            <Button color="inherit" size="small" onClick={reloadFun}>
              刷新
            </Button>
          )
        }>
        {nodataLabel || "没有数据"}
      </Alert>
    ),
  };

  if (!error && !data) {
    return res.loading();
  } else if (error) {
    return res.error();
  } else if (data && data.data.length === 0) {
    return res.nodata();
  } else {
    return (
      <Pagination
        sx={{ mt: 2 }}
        page={query.skip / query.limit + 1}
        count={Math.ceil(data.count / query.limit)}
        onChange={(e, page) =>
          setQuery({ ...query, skip: (page - 1) * query.limit })
        }
      />
    );
  }
}
