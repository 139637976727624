import { FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { FormProps } from "./Form.interface";

export default function FormSwitch(props: FormProps) {
  const { name, label, onChange, value } = props;
  const handleChange = (e: { target: { checked: any } }) => {
    onChange({ target: { name, value: e.target.checked } });
  };

  return (
    <FormControlLabel
      control={<Switch checked={!!value} onChange={handleChange} />}
      label={label}
    />
  );
}
