import { Add, AddCard, DeleteForever, Edit } from "@mui/icons-material";
import {
  Alert,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { financeStandards } from "../common/config";
import { IFormEvent } from "../form/Form.interface";
import FormCheckbox from "../form/FormCheckbox";
import FormSelect from "../form/FormSelect";
import FormSwitch from "../form/FormSwitch";
import FormText from "../form/FormText";
import { IcontextStatue, IfinanceCategory } from "../interface";
import { login, snk } from "../services/app.service";
import { fetchFinance } from "../services/financeFuns";
import pinyin from "../services/pinyin";
import Util from "../services/util";
import MoreMenu from "../ui/MoreMenu";

export default function FinanceSettingCategory() {
  const [context, setContext] = useOutletContext() as IcontextStatue;
  const { finance } = context;
  const [category, setCategory] = React.useState(finance?.category);
  const [query, setQuery] = React.useState({ tab: "1", expand: true });
  const [form, setForm] = React.useState<any>();
  const [autoPy, setAutoPy] = React.useState(true);

  React.useEffect(() => {
    setCategory(finance!.category);
  }, [finance]);

  function handleQueryChange(e: IFormEvent): void {
    setQuery(Util.formChange(e, query));
  }

  function handleFormChange(e: IFormEvent) {
    const { name, value } = e.target;
    if (name === "name") {
      form.py = pinyin.getFirstLetter(value).join("");
    }
    setForm(Util.formChange(e, form));
  }

  async function handleSave() {
    if (!category) return;
    let _category = category.map((i) => ({
      ...i,
      _children: undefined,
      _p: undefined,
      _childrenCodeLen: undefined,
    }));
    //=============================================================={ 删除 }
    if (form._del) {
      _category = _category.filter(
        (i) => !new RegExp("^" + form.code).test(i.code)
      );
    } else if (form._reset) {
      _category = [];
    } else {
      const index = _category.findIndex((i) => i.code === form.code);
      if (index > -1) {
        _category[index] = form;
      } else {
        _category.push({ ...form });
      }
    }
    save(
      form._reset ? [] : _category,
      form._reset ? form.standards : undefined
    );
  }

  if (!finance || !category) return null;
  const isManage =
    Util.intersection(
      ["manage", "accountant"],
      finance.members.find((i) => i._id === login.me?._id)?.roles || []
    ).length > 0;
  return (
    <Stack sx={{ p: 2 }}>
      {!isManage && (
        <Alert severity="warning">账套主管或会计可以修改, 出纳仅查看</Alert>
      )}
      <Stack direction="row" spacing={2} alignItems="center">
        <Tabs
          value={query.tab}
          onChange={(e, tab) => setQuery({ ...query, tab })}>
          <Tab value="1" label="资产" />
          <Tab value="2" label="负债" />
          <Tab value="3" label="权益" />
          <Tab value="4" label="成本" />
          <Tab value="5" label="损益" />
        </Tabs>
        <Stack flexGrow={1}> </Stack>
        <FormSwitch
          label="展开"
          name="expand"
          value={query.expand}
          onChange={handleQueryChange}
        />
        {isManage && (
          <React.Fragment>
            <Button
              startIcon={<Add />}
              onClick={() => {
                const i = category.find((x) => x.code === query.tab)!;
                const newCode =
                  Math.max(
                    ...(i._children?.map((i) => Number(i.code)) || []),
                    Number(i.code + "00")
                  ) + 1;
                setForm({
                  code: `${newCode}`,
                  name: "新科目",
                  de: i.de,
                  status: i.status,
                  py: "",
                  _add: true,
                  currency: "RMB",
                });
              }}>
              添加
            </Button>
            <MoreMenu
              menuItem={[
                {
                  title: "导入科目设置",
                  onClick: async () => {
                    const text = await Util.readTextFile();
                    if (text) {
                      const _category = text.split("\n").map((i) => {
                        const [code, name, py, de, currency, status] =
                          i.split(",");
                        return {
                          code,
                          name,
                          py,
                          de: de.trim() === "借",
                          currency,
                          status: status.trim() === "启用",
                        };
                      });
                      save(_category);
                    } else {
                      snk.next({ msg: "打开文件失败", severity: "error" });
                    }
                  },
                },
                {
                  title: "导出科目设置",
                  onClick: () => {
                    const jsonData = category
                      .filter((i) => i.code.length > 1)
                      .map((i) => ({
                        编码: i.code,
                        名称: i.name,
                        助记码: i.py,
                        余额方向: i.de ? "借" : "贷",
                        币种: i.currency,
                        状态: i.status ? "启用" : "停用",
                      }));
                    Util.toXlsxFile(
                      jsonData,
                      `科目设置-${finance.name}-${format(
                        Date.now(),
                        "yyMMdd"
                      )}.xlsx`
                    );
                  },
                },
                {
                  title: "初始化..",
                  onClick: () => {
                    setForm({ _reset: true, standards: finance.standards });
                  },
                },
              ]}
            />
          </React.Fragment>
        )}
      </Stack>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>编码</TableCell>
            <TableCell>名称</TableCell>
            <TableCell>子级</TableCell>
            <TableCell>助记码</TableCell>
            <TableCell>余额方向</TableCell>
            <TableCell>币种</TableCell>
            <TableCell>状态</TableCell>
            {isManage && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {_row(category.find((x) => x.code === query.tab)?._children, 1)}
        </TableBody>
      </Table>
      {form && _form()}
    </Stack>
  );

  async function save(_category: IfinanceCategory[], resetStandards?: string) {
    _category = _category
      .filter((i) => i.code.length > 1)
      .sort((a, b) => a.code.localeCompare(b.code));
    const r = await axios.post("finance/saveFinance", {
      orgId: context.orgId,
      financeId: context.finance!._id,
      category: _category,
      resetStandards,
    });
    if (r.data) {
      setForm(undefined);
      fetchFinance(context.finance!._id!, context.orgId!).then((r) => {
        setContext({ ...context, finance: r });
      });
    }
    return _category;
  }

  function _row(items?: IfinanceCategory[], len = 0) {
    return items?.map((i, index) => (
      <React.Fragment key={index}>
        <TableRow hover>
          <TableCell sx={{ pl: len }}>{i.code}</TableCell>
          <TableCell sx={i.status ? {} : { textDecoration: "line-through" }}>
            {i.name}
          </TableCell>
          <TableCell sx={{ color: "grey" }}>
            {i._children?.length ? `+${i._children.length}` : ""}
          </TableCell>
          <TableCell>{i.py?.toLocaleLowerCase()}</TableCell>
          <TableCell sx={{ p: 0 }}>
            {i.de ? (
              <Chip color="success" label="借" />
            ) : (
              <Chip color="warning" label="贷" />
            )}
          </TableCell>
          <TableCell>{i.currency}</TableCell>
          <TableCell>{i.status ? "启用" : "停用"}</TableCell>
          {isManage && (
            <TableCell sx={{ p: 0 }}>
              <IconButton
                onClick={() =>
                  setForm({ ...i, _children: undefined, _p: undefined })
                }>
                <Edit />
              </IconButton>
              <MoreMenu
                menuItem={[
                  {
                    icon: <AddCard />,
                    title: "添加下级",
                    onClick: () => {
                      const newCode =
                        Math.max(
                          ...(i._children?.map((i) => Number(i.code)) || []),
                          Number(i.code + "00")
                        ) + 1;
                      setForm({
                        code: `${newCode}`,
                        name: "新科目",
                        de: i.de,
                        status: i.status,
                        py: "",
                        _add: true,
                      });
                    },
                  },
                  {
                    icon: <DeleteForever />,
                    title: "删除",
                    onClick: () =>
                      setForm({
                        code: i.code,
                        name: i.name,
                        _del: true,
                      }),
                  },
                ]}
              />
            </TableCell>
          )}
        </TableRow>
        {query.expand &&
          (i._childrenCodeLen || 0) > 0 &&
          _row(i._children, i.code.length + 1)}
      </React.Fragment>
    ));
  }

  function _form() {
    form.currency = form.currency || "RMB";
    return (
      <Dialog open={true} onClose={() => setForm(undefined)}>
        <DialogTitle>{form.code}</DialogTitle>
        {form._del ? (
          <DialogContent>确定删除"{form.name}" 及所有下级吗?</DialogContent>
        ) : form._reset ? (
          <DialogContent sx={{ minWidth: 300 }}>
            <Stack spacing={2}>
              <Typography variant="h6">初始化为</Typography>
              <FormSelect
                sx={{ minWidth: 200 }}
                label="会计准则"
                name="standards"
                value={form.standards}
                options={financeStandards}
                onChange={handleFormChange}
              />
              <Alert severity="warning">将重设所有科目为默认</Alert>
            </Stack>
          </DialogContent>
        ) : (
          <DialogContent>
            <Stack sx={{ mt: 2 }} spacing={2}>
              {form._add && (
                <FormText
                  label="编码"
                  name="code"
                  value={form.code}
                  onChange={handleFormChange}
                />
              )}
              <FormText
                label="名称"
                name="name"
                value={form.name}
                onChange={handleFormChange}
              />
              <Stack direction="row" spacing={1}>
                <FormText
                  label="助记码"
                  name="py"
                  value={form.py}
                  onChange={handleFormChange}
                />
                <FormCheckbox
                  label="自动拼音"
                  name="autoPy"
                  value={autoPy}
                  onChange={(e) => {
                    setAutoPy(e.target.value);
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>余额方向</Typography>
                <ButtonGroup>
                  <Button
                    variant={form.de ? "contained" : "outlined"}
                    onClick={() => {
                      setForm({ ...form, de: true });
                    }}>
                    借
                  </Button>
                  <Button
                    variant={!form.de ? "contained" : "outlined"}
                    onClick={() => {
                      setForm({ ...form, de: false });
                    }}>
                    贷
                  </Button>
                </ButtonGroup>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormSelect
                  label="币种"
                  name="currency"
                  value={form.currency}
                  onChange={handleFormChange}
                  options={finance!.currency.map((i) => ({
                    title: i.name + "/" + i._id,
                    value: i._id,
                  }))}
                />
                <FormSwitch
                  label="启用科目"
                  name="status"
                  value={form.status}
                  onChange={handleFormChange}
                />
              </Stack>
            </Stack>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => setForm(undefined)}>取消</Button>
          <Button variant="contained" onClick={handleSave}>
            确定
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
