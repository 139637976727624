import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { financeStandards } from "../common/config";
import { IFormEvent } from "../form/Form.interface";
import FormDate from "../form/FormDate";
import FormSelect from "../form/FormSelect";
import FormText from "../form/FormText";
import { Ifinance } from "../interface";
import { login } from "../services/app.service";
import pinyin from "../services/pinyin";
import Util from "../services/util";
// 创建修改账套
export default function FinanceForm(props: {
  finance: Ifinance;
  onClose: () => void;
}) {
  const [y] = Util.ymd();
  const { onClose, finance } = props;
  const [form, setForm] = React.useState({
    ...{
      beginAt: { date: new Date(y, 0, 1).valueOf() },
      mark: "",
      standards: "",
      name: "",
    },
    ...finance,
  });

  function handleChange(e: IFormEvent) {
    setForm(Util.formChange(e, form));
  }

  function autoPinyin() {
    form.mark = pinyin.getFirstLetter(form.name).join("");
    setForm({ ...form });
  }
  // eslint-disable-next-line
  React.useEffect(autoPinyin, [form.name]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{form._id ? "修改账套" : "新建账套"}</DialogTitle>
      <DialogContent>
        <Stack sx={{ mt: 2 }} spacing={2}>
          <FormText
            label="账套名称"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
          <Stack direction="row" spacing={2}>
            <FormText
              label="助记码"
              name="mark"
              value={form.mark}
              onChange={handleChange}
            />
            <Button onClick={autoPinyin}>简拼</Button>
          </Stack>
          {!form._id && (
            <FormSelect
              variant="outlined"
              name={"standards"}
              value={form.standards}
              label={"会计准则"}
              options={financeStandards}
              onChange={handleChange}
            />
          )}
          <FormDate
            value={form.beginAt?.date || new Date(y, 0, 1)}
            name="beginAt.date"
            label="期初日期"
            onChange={handleChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!form.name || (!form._id && !form.standards) || !form.mark}
          variant="contained"
          onClick={async () => {
            const d = Util.ymd(form.beginAt.date);
            form.beginAt = { ...form.beginAt, y: d[0], m: d[1], d: d[2] };
            if (form._id) {
              const { _id, orgId, name, mark, beginAt } = form;
              const r = await axios.post("org/saveFinance", {
                _id,
                orgId,
                data: { name, mark, beginAt },
              });
              r.data && onClose();
            } else {
              const { nickname: name, _id, mobile } = login.me!;
              const r = await axios.post("org/createFinance", {
                orgId: form.orgId,
                data: {
                  ...form,
                  members: [{ name, _id, mobile, roles: ["manage"], on: true }],
                },
              });
              r.data?._id && onClose();
            }
          }}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
}
